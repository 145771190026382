import React, { useEffect, useContext, useState, useLayoutEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { notification, Button } from 'antd'
import styles from './index.module.scss'
import cartIcon from '../../assets/img/cart_icon.png'
import sessionIcon from '../../assets/img/session_icon.png'
import orderApi from '../../api/orderApi'
import MainContext from '../../stores/stores'

import { useStateContext } from "@/YXUIKit/im-kit-ui/src";
import { observer } from 'mobx-react'

let lastIdClient: string = ''

const Sider: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { commonStore, setCommonStore } = useContext(MainContext)
  

  useEffect(() => {
    let token = localStorage.getItem('token')
    if(token) {
      getCartList()
    }
  }, [])
  const getCartList = () => {
    orderApi.getCartList().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data
        let length = 0
        Object.keys(_data).forEach((items: string) => {
          Object.keys(_data[items].goods_list).forEach((item: string) => {
            length += 1
          })
        })
        setCommonStore({
          type: 'cartNum',
          value: length 
        })
      }
    })
  }
  const toGo = (url: string) => {
    navigate(url)
  }
  
  return (
    <div className={styles['sider-wrap']}>
      <div className={styles['sider-item']} onClick={() => toGo('/cart')}>
        <img src={cartIcon} alt="" />
        <span className={styles['sider-title']}>{intl.formatMessage({id: '购物车'})}({commonStore.cartNum})</span>
      </div>
      {/* {
        commonStore.imChatData.appKey ? <SideChat/> : null
      } */}
    </div>
  )
}

const SideChat: React.FC = observer(() => {
  const { pathname } = useLocation()
  const intl = useIntl()
  const navigate = useNavigate()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { store, nim } = useStateContext();
  const unreadMsgsCount = store.uiStore.sessionUnread || 0

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const onMsg = (msg: any) => {
      console.log("sider msg:", msg)
      let sessionId = msg.sessionId
      let idClient = msg.idClient
      let custom = msg.custom
      let fromClientType = msg.fromClientType
      let type = msg.type
      let body = msg.body
      if(type === 'text') {
        let msgTextObj = msg.body && JSON.parse(msg.body) ? JSON.parse(msg.body) : {}
        let isFromOrder = msgTextObj.notice_type && (msgTextObj.notice_type === 'refund' || msgTextObj.notice_type === 'order_quote_finished' || msgTextObj.notice_type === 'order_wait_send' || msgTextObj.notice_type === 'user_verify') ? true : false
        if(isFromOrder) {
          store.sessionStore.deleteSessionActive(sessionId)
          setCommonStore({
            type: 'msgNoticeData',
            value: msg
          })
          openNotification(msgTextObj)
        }
      }
    }
    nim.on('msg', onMsg)

    return () => {
      nim.off('msg', onMsg)
    }
  }, [nim, unreadMsgsCount])


  const openNotification = (data: any) => {
		if(data.notice_type === 'refund') {
		  const key = `open${Date.now()}`;
		  const btn = (
		    <Button 
					type="primary" 
					onClick={() => {
						api.destroy();
						if(pathname === '/mine/aftersales/detail') {
							window.location.reload()
						}else {
							navigate(`/mine/aftersales/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.inquiry_id}&refund_id=${data.refund_id}`)
						}
					}}>
					{intl.formatMessage({ id: '查看详情' })}
				</Button>
		  );
		  let refund_status = data.refund_status
		  let message;
		  if(refund_status === 1) {
		    message = intl.formatMessage({ id: '售后订单商家已同意！' })
		  }else {
		    message = intl.formatMessage({ id: '售后订单商家已拒绝！' })
		  }
		  api.open({
		    message: message,
		    duration: null,
		    description:(
		      <div>
		        <div className="mt10">
							{intl.formatMessage({ id: '创建时间' })}:{data.create_time}
						</div>
						<div className="mt10">
              {intl.formatMessage({ id: '处理时间' })}:{data.send_time}
						</div>
		      </div>
		    ),
		    btn,
		    key,
		    // onClose: close,
		  });
		}
    if(data.notice_type === 'order_quote_finished' || data.notice_type === 'order_wait_send') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={
					() => {
						if(window.location.pathname === '/mine/order/detail') {
						  window.location.reload()
						}else {
						  api.destroy(); 
							navigate(`/mine/order/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.enquiry_info.enquiry_id}`)
						}
					}
				}
				>{intl.formatMessage({ id: '查看详情' })}</Button>
      );
      let inquiry_status = data.inquiry_status
      let car_info = data.car_info 
      let message;
      if(inquiry_status === 5) {
        message = intl.formatMessage({ id: '订单已报价，请尽快处理！' })
      }else {
        message = intl.formatMessage({ id: '订单已发货，请注意查收！' })
      }
      api.open({
        message: message,
        duration: null,
        description:(
          <div>
            {
              car_info ? (
                <div className="flex-s-c">
                  {car_info.brand_logo ? <img style={{ height: 20, border: '1px solid #f6f6f6' }} src={car_info.brand_logo} /> : ''}
                  {car_info.market_name}
                </div>
              ): null
            }
            <div className="mt20">{intl.formatMessage({ id: '创建时间' })}：{data.order_create_time}</div>
            {
              inquiry_status === 5 ? (
                <div className="mt10">{intl.formatMessage({ id: '报价时间' })}：{data.quote_time}</div>
              ): inquiry_status === 8 ? (
                <div className="mt10">{intl.formatMessage({ id: '发货时间' })}：{data.send_time}</div>
              ): null
            }
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
    if(data.notice_type === 'user_verify') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={() => {
          if(window.location.pathname === '/mine/company') {
            window.location.reload()
          }else {
            api.destroy(); navigate(`/mine/company`)
          }
        }
        }>{intl.formatMessage({ id: '前往处理' })}</Button>
      );
      let phone = data.phone
      let _phone = phone.slice(-4)
      let message = `尾号${_phone}用户，提交了认证审核！`
      let time = data.create_time
      api.open({
        message: intl.formatMessage({ id: '审核单' }),
        duration: null,
        description:(
          <div>
            <div style={{color: '#333333', fontSize: 14}}>{message}</div>
            <div style={{marginTop: 8, color: '#888888', fontSize: 14}}>{time}</div>
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
  };

  const toGo = (url: string) => {
    nim.connect().then(() => {
      console.log('连接成功')
      navigate(url)
    })
  }
  return (
    <>
      {contextHolder}
      <div className={styles['sider-item']} style={{borderTop: '1px solid #434343'}} onClick={() => toGo('/IMChat')}>
        <img src={sessionIcon} alt="" />
        <span className={styles['sider-title']}>{intl.formatMessage({id: '聊天'})}({unreadMsgsCount})</span>
      </div>
    </>
    
  )
})
export default Sider