import React, { useState, useEffect, useReducer, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import MainContext from '@/stores/stores'
import { Button, message, Popover, InputNumber, Spin } from "antd"
import Copy from '@/components/Copy'
import { PartSearch, Goods } from '@/pages/Epc'
import { hex_md5 } from '@/utils/md5'
import vinApi from '@/api/epcApi'
import listApi from '@/api/listApi'
import orderApi from '@/api/orderApi'
import { useIntl } from 'react-intl'

import styles from './vin.module.scss'


interface PropsIn {
  detail?: any,
  pid: string,
  brandCode: string,
  mcid?: string,
  mid?: string,
  num?: string,
  queryType: string,
  typeActive?: string,
  quantity?: number
	defaultPic?: string
}
const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'basic':
      return {
        ...state,
        basic: {
          name: '基础信息',
          data: action.value
        }
      };
    case 'goods':
      return {
        ...state,
        goods: {
          name: '商品信息',
          data: action.value
        }
      };
    case 'price':
      return {
        ...state,
        price: {
          name: '4S价格',
          data: action.value
        }
      };
    case 'replace':
      return {
        ...state,
        replace: {
          name: '替换件',
          data: action.value
        }
      };
    case 'stocks':
      return {
        ...state,
        stocks: {
          name: '在线库存',
          data: action.value
        }
      };
    case 'articles':
      return {
        ...state,
        articles: {
          name: '知名品牌',
          data: action.value
        }
      };
    case 'imported':
      return {
        ...state,
        imported: {
          name: '进口品牌',
          data: action.value
        }
      };
    case 'applicable':
      return {
        ...state,
        applicable: {
          name: '适用车型',
          data: action.value
        }
      };
    default:
      return state
  }
}

let pollingIs = true
let pollingNum = 0

// const dmsbarnd = ['benz']

const PartDetail: React.FC<PropsIn> = (props) => {
  const lang = localStorage.getItem("lang") || 'zh'
  const cur = lang === 'ru' ? '₽' : lang === 'en' ? '$' : '￥'
  const navigate = useNavigate()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const intl = useIntl()
  const { crumb } = commonStore
  const { detail = {}, pid, brandCode, mcid = '', mid = '', num = '', typeActive, quantity, queryType, defaultPic } = props
  const [ tabActive, setTabActive ] = useState<string>(typeActive || 'basic')
  const [ tabBrandActive, setTabBrandActive ] = useState<string>('All')
  const [ photo, setPhoto ] = useState<any>([])
  const [ qrcode, setQrcode ] = useState<string>('')
  const [ photoActive, setPhotoActive ] = useState<number>(0)
  const [ uploadLoading, setUploadLoading ] = useState<boolean>(false)

  const [ partSearchActive, setPartSearchActive ] = useState<any>({})
  const [ partSearchkey, setPartSearchkey ] = useState<any>(0)

  const [ inquiryVisible, setInquiryVisible ] = useState<boolean>(false)
  const [ goodsLoading, setGoodsLoading ] = useState<boolean>(false)
  const [ supportBuy, setSupportBuy ] = useState<any>({DMS: {}})
  const [ supportBasic, setSupportBasic ] = useState<any>({})

  const [ buyActive, setBuyActive ] = useState<any>({index: 0, quantity: quantity || 1})
  const [ createInquiryLogin, setCreateInquiryLogin ] = useState<boolean>(false)
  
  const [ data, setData ] = useReducer(reducer, {
    basic: {
      name: '基础信息'
    },
    goods: {
      name: '商品信息',
      visible: true
    },
    price: {
      name: '4S价格'
    },
    replace: {
      name: '替换件'
    },
    stocks: {
      name: '在线库存'
    },
    articles: {
      name: '知名品牌'
    },
    imported: {
      name: '进口品牌'
    },
    applicable: {
      name: '适用车型'
    }
  })
  useEffect(()=>{
    getBaseinfo()
    replace()
    // stocks()
    // articles()
    applicable('')
    partPrice()
    
    return unmount
  }, [])

  useEffect(()=>{
    if(partSearchActive.mcid){
      setPartSearchkey(partSearchkey + 1)
    }
  }, [partSearchActive.mcid])
  
  useEffect(()=>{
    if(typeActive){
      scrollToAnchor(typeActive)
    }
  }, [data.goods.data])

  const unmount = () => {
    if(pollingNum){
      pollingIs = false
    }
  }

  const getBaseinfo = () => {
    let param = {
      maintainVinInputValue: detail?.vin || '',
      pid,
      brandCode,
      mcid,
      mid,
      num,
      query_type: queryType
    }
    vinApi.baseinfo(param).then((result: any)=>{
      if(result.code === 1){
        const d = result.data
        let baseinfo = [
          {
            key: intl.formatMessage({ id: "原厂OE号" }),
            value: d.pid,
            copy: true
          },
          {
            key: intl.formatMessage({ id: "零件名称" }),
            value: d.label
          },
          {
            key: intl.formatMessage({ id: "型号" }),
            value: d.model,
          },
          {
            key: intl.formatMessage({ id: "工程编号" }),
            value: d.engpid
          },
          {
            key: intl.formatMessage({ id: "备注" }),
            value: d.remark
          },
          // {
          //   key: 'Update time',
          //   value: d.updatetime
          // }
        ]
        let pic: any = []
        if(d.images && d.images.length && result.image_host){
          d.images.forEach((img: string)=>{
            pic.push({
              pic: `${result.image_host}/${img}`
            })
          })
        }

        let basic = {
          pic,
          label: d.label,
          info: [
            {
              key: intl.formatMessage({ id: "原厂OE号" }),
              value: d.pid
            },
            {
              key: intl.formatMessage({ id: "零件名称" }),
              value: d.label
            }
          ]
        }
        // uploadQrcode(d.label)
        // setPhoto(pic)
        setSupportBasic(basic)
        setData({value: baseinfo, type: 'basic'})
				if(!pic.length && defaultPic) {
					pic.push({
					  pic: defaultPic
					})
				}
        getDmsBrand(pic)

        // setData({value: d.price, type: 'price'})
        if(detail && detail.vin) {
          const keyword = [
            detail.vin,
            `${brandCode}-${d.label.replace('\n', '')}`,
            brandCode
          ]
          const hp = {
            query_type: 'vin',
            keyword: keyword.join('\n')
          }
          vinApi.setHistory(hp).then()
        }else if(queryType === 'part_search'){
          const keyword = [
            pid,
            `${brandCode}-${d.label.replace('\n', '')}`,
            brandCode
          ]
          
          const hp = {
            query_type: 'part',
            keyword: keyword.join('\n'),
            brandCode: brandCode
          }
          vinApi.setHistory(hp).then()
        }
      }
    })
  }
  
  const replace = () => {
    vinApi.replace({pid, brandCode}).then((result: any)=>{
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        setData({value: {directly, indirect}, type: 'replace'})
      }
    })
  }
  // const stocks = () => {
  //   vinApi.stocks({oe: pid, amount: 8}).then((result: any) => {
  //     if(result.code === 1 && result.data) {
  //       setData({value: result.data.stocks || [], type: 'stocks'})
  //     }
  //   })
  // }
  const articles = () => {
    vinApi.articles({pid, brandCode}).then((result: any) => {
      if(result.code === 1 && result.data) {
        let tabData1: any = []
        let tabData2: any = []
        result.data.forEach((item: any) => {
          if(item.supplier_detail.regional === "FOREIGN") {
            tabData2.push(item)
          }else {
            if(item.supplier_detail && item.supplier_detail.logo_url){
              item.brand_logo = item.supplier_detail.image_host + '/' + item.supplier_detail.logo_url
            }
            if(item.images && item.images[0]){
              item.images = item.images[0]
            }
            tabData1.push(item)
          }
        })
        setData({value: tabData1 || [], type: 'articles'})
        setData({value: tabData2 || [], type: 'imported'})
      }
    })
  }
  
  const applicable = (brand: string) => {
    let param: any = {
      part: pid
    }
    if(brand && brand !== 'All'){
      param.filter_brand = brand
    }
    vinApi.applicable(param).then((result: any)=>{
      if(result.code === 1){
        result.all_brands.unshift(['All', intl.formatMessage({ id: '全部' })])
        setData({value: {title: result.all_brands, data: result.data}, type: 'applicable'})
      }
    })
  }

  const pics = () => {
    vinApi.pics({pid, brandCode}).then((result: any) => {
      if(result.code === 1 && result.data) {
        setPhoto(result.data)
      }
    })
  }
  const removePic = (e: any, item: any, index: number) => {
    e.stopPropagation()
    vinApi.removePic({
      pid: item.pid,
      pic_id: item.id
    }).then((result: any) => {
      if(result.code === 1) {
        const temp = JSON.parse(JSON.stringify(photo))
        temp.splice(index, 1)
        setPhoto(temp)
        setPhotoActive(0)
        message.success(intl.formatMessage({ id: '删除成功！' }))
      }
    })
  }

  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    setUploadLoading(false)
    if(result.code === 1){
      pics()
    }
  }
  const error = (result: any) => {
    message.warning(intl.formatMessage({ id: '上传失败！' }))
    setUploadLoading(false)
  }
  // const uploadQrcode = (name: string) => {
  //   commonApi.uploadQrcode({pid, brandCode, name}).then((result: any)=>{
  //     if(result.code === 1){
  //       setQrcode('data:image/png;base64,' + result.data)
  //     }
  //   })
  // }
  const scrollToAnchor = (id: string) => {
    console.info(id)
    if (id) {
      let anchorElement = document.getElementById(id)
      if(anchorElement) { anchorElement.scrollIntoView() }
    }
  }
  const scroll = () => {
    let content = document.getElementById('content')
    let top = content?.scrollTop
    let contop = content?.offsetTop || 0
    let active = ''
    Object.keys(data).forEach((key)=>{
      const offsetTop = document.getElementById(key)?.offsetTop || 0
      if(offsetTop && (top || top === 0) && top >= offsetTop - contop - 10){
        if(top >= offsetTop - contop - 10){
          active = key
        }
      }
    })
    setTabActive(active)
  }

  const partPrice = () => {
    // if(brandCode === 'benz'){
    //   axios.all([vinApi.partPrice({pid, brandCode}), vinApi.buyPartPrice({pid, brandCode})]).then(axios.spread((partResult, buyResult)=>{
    //     if(partResult.code === 1){
    //       if(buyResult.code === 1 && buyResult.data.DMS ){
    //         partResult.data.forEach((item: any)=>{
    //           if(item.name === buyResult.data.DMS.name && item.firm_code === buyResult.data.DMS.firm_code){
                
    //           }
    //         })
    //       }
    //       setData({value: partResult.data, type: 'price'})
    //     }
    //   }))
    // }
    vinApi.partPrice({pid, brandCode}).then((result: any)=>{
      if(result.code === 1){
        setData({value: result.data, type: 'price'})
      }
    })
  }
  const cancelFn = () => {
    setPartSearchActive({})
    setPartSearchkey(0)
  }
  const buyPartPrice = () => {
    setGoodsLoading(true)
    vinApi.buyPartPrice({pid, brandCode}).then((result: any)=>{
      setGoodsLoading(false)
      if(result.code === 1){
        let stockData: any = []
        if(result.data.extra){
          const ext = result.data.extra
          const dms = result.data.DMS
          stockData = [
            {
              key: intl.formatMessage({ id: "更新时间" }),
              val: dms.update_date || ''
            },
            {
              key: intl.formatMessage({ id: "进货价(含税)" }),
              val: dms['purchase_price'] ? `￥${dms['purchase_price'] / 100}` : ''
            },
            {
              key: intl.formatMessage({ id: "销售价(含税)" }),
              val: dms['price'] ? `￥${dms['price'] / 100}` : ''
            },
            {
              key: intl.formatMessage({ id: "库存数量" }),
              val: ext.number || ''
            },
            {
              key: intl.formatMessage({ id: "长" }),
              val: ext['length'] ? `${ext['length'].toFixed(2)}${intl.formatMessage({ id: '米' })}` : ''
            },
            {
              key: intl.formatMessage({ id: "宽" }),
              val: ext['width'] ? `${ext['width'].toFixed(2)}${intl.formatMessage({ id: '米' })}` : ''
            },
            {
              key: intl.formatMessage({ id: "高" }),
              val: ext['height'] ? `${ext['height'].toFixed(2)}${intl.formatMessage({ id: '米' })}` : ''
            },
            {
              key: intl.formatMessage({ id: "重" }),
              val: ext['weight'] ? `${ext['weight'].toFixed(2)}kg` : ''
            },
            {
              key: intl.formatMessage({ id: "净重" }),
              val: ext['weight_net'] ? `${ext['weight_net'].toFixed(2)}kg` : ''
            },
            {
              key: intl.formatMessage({ id: "危险品" }),
              val: ext.hazardous ? intl.formatMessage({ id: "危险品" }) : intl.formatMessage({ id: "非危险品" })
            },
            {
              key: intl.formatMessage({ id: "易碎品" }),
              val: ext.fragile ? intl.formatMessage({ id: "易碎品" }) : intl.formatMessage({ id: "非易碎品" })
            },
            {
              key: intl.formatMessage({ id: "税点" }),
              val: ext['tax'] ? `${ext['tax'] * 100}%` : ''
            },
            {
              key: intl.formatMessage({ id: "税额" }),
              val: ext['tax_price'] ? `￥${ext['tax_price'] / 100}` : ''
            },
            {
              key: intl.formatMessage({ id: "备注信息" }),
              val: ext.description || ''
            }
          ]
        }
        result.data.stockData = stockData
        setSupportBuy(result.data)
      }
    })
  }

  const getGoods = (dmsbarnd: any, pic: any) => {
    let value: any = []
    let tempPic = pic
    const dms = {
      brand_names: intl.formatMessage({ id: "原厂大库" }),
      unit: cur,
      quality_price: '',
      type: 'dms',
      imgIndex: 0
    }
    if(dmsbarnd.includes(brandCode)){
      value.push(dms)
    }
    listApi.getStock({oe: pid}).then((result: any)=>{
      if(result.code === 1 && result.data && result.data.quality_price && result.data.quality_price.length){
        // const param = {
        //   goods_codes: result.data
        // }
        // listApi.partListPrice(param).then((goodsResult: any)=>{
        //   if(goodsResult.code === 1){
        //     value = [...value , ...Object.values(goodsResult.data)]
        //     console.info(value)
        //   }
        //   setData({value, type: 'goods'})
        // })
        result.data.quality_price.forEach((qpItem: any)=>{
          qpItem.product_params = JSON.parse(qpItem.product_params || '{}')
          if(qpItem.images){
            qpItem.imgIndex = tempPic.length
            qpItem.images = qpItem.images.split(',')
            qpItem.images.forEach((img: string)=>{
              tempPic.push({
                pic: img
              })
            })
          }
        })
        
        value = [...value , ...Object.values(result.data.quality_price)]
      }else if(!dmsbarnd.includes(brandCode)) {
        value.push({
          type: 'inquiry'
        })
      }
			console.log(tempPic)
      setPhoto(tempPic)
      if(value.length){
        setData({value, type: 'goods'})
      }else {
        setInquiryVisible(true)
      }
      
    })
  }
  const updateBuyActive = (type: string, value: any) => {
    let temp = JSON.parse(JSON.stringify(buyActive))
    temp[type] = value
    if(type === 'index' && data.goods.data[value].type === 'dms' && !supportBuy.DMS.purchase_price){
      buyPartPrice()
    }
    
    setPhotoActive(data.goods.data[value].imgIndex || 0)
    setBuyActive(temp)
  }

  const addShopcart = (buy: boolean) => {
    const item = data.goods.data[buyActive.index]
    let param = {}
    let type = 'oe'
    let title: any = []
    let hash_value = hex_md5('goods' + pid)
    if(detail.vin){
      hash_value = hex_md5('goods' + detail.vin)
    }
    
    if(queryType === 'vin' || queryType === 'cars'){
      crumb.forEach((item: any)=>{
        if(!item.type || item.type === 'config'){
          title.push(item.name)
        }
      })
    }else {
      title = [pid]
    }
    title = title.join('>')
    console.log("title:", title, 'detail:', detail)
    if(item.type === 'dms' && !supportBuy.DMS.firm_code){
      item.type = 'inquiry'
    }
    if(item.type === 'dms'){
      type = 'epc'
      // let hash_msg = 'goods' + '&' + pid + '&' + pid
      // let hash_value = hex_md5(hash_msg)
      param = {
        query_type: detail.vin ? 'vin' : 'oe',
        query_info: {
          oe: pid,
          brandcode: brandCode,
          // basic: supportBasic,
          // title,
          // supportBuy,
          vin: detail.vin,
          mcid,
        },
        goods_info: {
          goods_code: pid,
          num: buyActive.quantity,
          stock_type: 'epc',
          fromDtl: 'part', // part 产品详情  goods商品详情  pid零件号详情
          data: {
            oe: pid,
            brandcode: brandCode,
            basic: supportBasic,
            title,
            supportBuy,
            detail
          }
        },
        hash_value: hash_value
      }
    }else if(item.type === 'inquiry'){
      type = 'yc'
      param = {
        query_type: detail.vin ? 'vin' : 'oe',
        query_info: {
          // title,
          brandcode: brandCode,
          // part_name: supportBasic.label,
          oe: pid,
          // amount: buyActive.quantity,
          // price_4s: data.price.data.length ? data.price.data[0].sale_price : '',
          // unit: '',
          // break_img: '',
          // standard_label: '',
          // pid_replacement: data.replace?.data?.directly.length ? data.replace.data.directly.map((item: any)=>{return item.pid[0].pid}): [],
          // img_point: {},
          // img: supportBasic.pic.length ? supportBasic.pic[0].pic : '',
          vin: detail.vin,
          mcid,
        },
        goods_info: {
          goods_code: pid || '',
          num: buyActive.quantity,
          stock_type: 'yc',
          fromDtl: 'part', // part 产品详情  goods商品详情  pid零件号详情
          data: {
            detail,
            title,
            brandcode: brandCode,
            part_name: supportBasic.label,
            oe: pid,
            amount: buyActive.quantity,
            price_4s: data.price.data.length ? data.price.data[0].sale_price : '',
            unit: '',
            break_img: '',
            standard_label: '',
            pid_replacement: data.replace?.data?.directly.length ? data.replace.data.directly.map((item: any)=>{return item.pid[0].pid}): [],
            img_point: {},
            img: supportBasic.pic.length ? supportBasic.pic[0].pic : ''
          }
        },
        hash_value: hash_value
      }
    }else{
      param = {
        query_type: detail.vin ? 'vin' : 'oe',
        query_info: {
          // title,
          // detail,
          code: item.goods_code,
          oe: pid,
          brandcode: brandCode,
          vin: detail.vin
        },
        goods_info: {
          goods_code: item.goods_code,
          num: buyActive.quantity,
          goods_cst_id: item.goods_cst_id,
          stock_type: 'erp',
          fromDtl: 'part', // part 产品详情  goods商品详情  pid零件号详情
          data: {
            title,
            detail,
            code: item.goods_code,
            oe: pid,
            brandcode: brandCode,
            mcid,
          }
        },
        hash_value: hash_value
      }
    }
    
    orderApi.addCart(param).then((res: any) => {
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "已添加至购物车" }))
        setCommonStore({
          type: 'cartNum',
          value: commonStore.cartNum ? commonStore.cartNum + 1 : 1
        })
        if(buy){
          navigate(`/cart`)
        }
      }
    })
  }
  const createInquiry = () => {
    let param = {
      "part_list": [
        {
          "part_name": supportBasic.label,
          "oe": pid,
          "amount": buyActive.quantity,
          "price_4s": data.price.data.length ? data.price.data[0].sale_price : '', //4s价
          "unit": "",
          "break_img": "",
          "standard_label": "",
          "pid_replacement": data.replace?.data?.directly.length ? data.replace.data.directly.map((item: any)=>{return item.pid[0].pid}): [],
          "img_point": {},
          "img": supportBasic.pic.length ? supportBasic.pic[0].pic : ''
        }
      ]
    }
    setCreateInquiryLogin(true)
    listApi.createInquiry(param).then((result: any)=>{
      setCreateInquiryLogin(false)
      if(result.code === 1){
        message.success(intl.formatMessage({ id: '询价成功，可在订单列表中查看！' }))
      }
    })
  }
  const getDmsBrand = (pic: any) => {
    vinApi.getDmsBrand().then((result: any)=>{
      if(result.code === 1){
        let arr = result.data.map((item: any)=>{
          return item.brandCode
        })
        // if(arr.includes(brandCode)){
        //   buyPartPrice()
        // }

        getGoods(arr, pic)
        // setDmsbarnd(arr)
      }else {
        getGoods([], pic)
      }
    })
  }
  return (
    <>
      <div className={styles.partDetail}>
        <ul className={styles.tab}>
          {
            Object.keys(data).map((key, index)=>{
              const item = data[key]
              return (
                item.data && JSON.stringify(item.data) !== '[]' || item.visible ? (
                  <li key={index} className={tabActive === key ? styles.active : ''} onClick={()=>scrollToAnchor(key)}>{intl.formatMessage({ id: item.name })}</li>
                ): null
              )
            })
          }
        </ul>
        <div className={styles.content} id="content" onScroll={()=>scroll()} style={{height: queryType === 'part_search' ? `calc(100vh - ${commonStore.pageType === '2' ? 152 : 232}px)` : '500px'}}>
          <div className={styles.row} id="basic">
            <div className={styles.basic}>
              {
                data.basic.data?.map((item: any, index: number)=>{
                  return (
                    item.value ? (
                      <div className={styles.item} key={index}>
                        <label>{item.key}</label>
                        {
                          item.key === '原厂OE号' ? <Copy icon={true} title={item.value} /> : <span dangerouslySetInnerHTML={{ __html: item.value }} />
                        }
                      </div>
                    ): null
                  )
                })
              }
            </div>
            <div className={styles.photo}>
              {
                photo.length ? (
                  <div className={styles.active}>
                    {
                      photo.length > 1 ? (
                        <>
                          <span className={styles.activeL} onClick={()=>setPhotoActive((photoActive - 1) < 0 ? photo.length - 1 : photoActive - 1)}>
                            <span className="iconfont icon-left"></span>
                          </span>
                          <span className={styles.activeR} onClick={()=>setPhotoActive((photoActive + 1) > photo.length - 1 ? 0 : photoActive + 1)}>
                            <span className="iconfont icon-right"></span>
                          </span>
                        </>
                      ) : null
                    }
                    {
                      photo[photoActive] ? (
                        <>
                          {
                            photo[photoActive].pic && (photo[photoActive].pic.includes(".mp4") || photo[photoActive].pic.includes(".mov") || photo[photoActive].pic.includes(".MOV")) ? (
                              <video controls src={photo[photoActive].pic} />
                            ): (
                              <img src={photo[photoActive].pic ? photo[photoActive].pic : 'https://cdns.007vin.com/img/autorepair/cst/no_item.png'} alt="" />
                            )
                          }
                        </>
                        // <img src={photo[photoActive].pic} />
                      ) : null
                    }
                  </div>
                ): null
              }
              <div className={styles.list}>
                {/* <div style={{display: 'inline-block'}}>
                  <Upload
                    multiple
                    parse={commonApi.upload}
                    setLoading={setUploadLoading}
                    loading={uploadLoading}
                    action={commonApi.uploadPath}
                    name="img"
                    data={{pid, brandCode, img_source: '0'}}
                    beforeUpload={beforeUpload}
                    start={start}
                    success={success}
                    error={error}
                  >
                    <div className={styles.upload}>+</div>
                  </Upload>
                  <p>上传图片</p>
                </div> */}
                {/* <div style={{display: 'inline-block'}}>
                  <div className={styles.qrcode}>
                    <img src={qrcode} />
                    <div><img src={qrcode} /></div>
                  </div>
                  <p>扫码上传</p>
                </div> */}
                {
                  photo.map((item: any, index: number)=>{
                    return (
                      <div key={index} className={index === photoActive ? (`${styles.itemActive} ${styles.item}`) : styles.item} onClick={()=>setPhotoActive(index)}>
                        {
                          item.yc_id === '007' ? <span className={styles.authority}>官方</span> : null
                        }
                        {
                          item.is_me ? <span className={`iconfont icon-shanchu1 ${styles.removePic}`} onClick={(e)=>removePic(e, item, index)}></span> : null
                        }
                        {
                          item.pic && (item.pic.includes(".mp4") || item.pic.includes(".mov") || item.pic.includes(".MOV")) ? (
                            <video src={item.pic} />
                          ): (
                            <img src={item.pic ? item.pic : 'https://cdns.007vin.com/img/autorepair/cst/no_item.png'} alt="" />
                          )
                        }
                        {/* <img src={item.pic} /> */}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {
            data.goods.data && data.goods.data.length || inquiryVisible ? (
              <div className={styles.box} id="goods">
                <h5>{intl.formatMessage({ id: "商品信息" })}</h5>
                <div className={styles.goods}>
                  <div className={styles.item}>
                    <label>{intl.formatMessage({ id: "品质(含税价)" })}</label>
                    {
                      
                        <div className={styles.quality}>
                          {
                            data.goods.data.map((item: any, index: number)=>{
                              return (
                                item.type === 'dms' ? (
                                  <Spin spinning={goodsLoading}>
                                    <div className={buyActive.index === index ? `${styles.price} ${styles.active}` : styles.price} onClick={()=>updateBuyActive('index', index)}>
                                      <span>{item.brand_names}</span>
                                      {
                                        supportBuy.DMS.purchase_price ? (
                                          <>
                                            {item.cur_unit}{supportBuy.DMS.purchase_price/100}
                                          </>
                                        ): (
                                          <>{intl.formatMessage({ id: "获取价格" })}</>
                                        )
                                      }
                                    </div>
                                  </Spin>
                                ): (
                                  item.type === 'inquiry' ? (
                                    <div className={buyActive.index === index ? `${styles.qualityInquiry} ${styles.active}` : styles.qualityInquiry} onClick={()=>updateBuyActive('index', index)}>
                                      {intl.formatMessage({ id: "询价" })}
                                    </div>
                                  ): (
                                    <div className={buyActive.index === index ? `${styles.price} ${styles.active}` : styles.price} onClick={()=>updateBuyActive('index', index)}>
                                      <span>{item.quality_name}</span>
                                      {item.quality_price ? (item.unit ? item.unit :'￥') : ''}{item.quality_price ? item.quality_price : intl.formatMessage({ id: "待报价" })}
                                    </div>
                                  )
                                  
                                )
                                
                              )
                            })
                          }
                        </div>
                      
                    }
                    
                  </div>
                  <div className={styles.item}>
                    <label>{intl.formatMessage({ id: '数量' })}</label>
                    <div>
                      <InputNumber min={1} value={buyActive.quantity} onChange={(val)=>updateBuyActive('quantity', val)} style={{marginRight: 6}} />
                      {
                        data.goods.data[buyActive.index].type !== 'inquiry' ? (
                          <span style={{color: '#8E8E8E'}}>
                            
                            {
                              data.goods.data[buyActive.index].type === 'dms' ? (
                                supportBuy.DMS.purchase_price ? (
                                  supportBuy.extra ? (
                                    supportBuy.extra.number?.substring(0, 10) + '库存'
                                  ) : null
                                ): null
                              ): (
                                data.goods.data[buyActive.index].inventoryNum > 5 ? intl.formatMessage({ id: "现货充足" }) : intl.formatMessage({ id: "现货紧张" })
                              )
                            }
                            </span>
                        ): null
                      }
                      
                    </div>
                  </div>
                  <div className={styles.item}>
                    <label></label>
                    {/* {
                      data.goods.data[buyActive.index].type === 'inquiry' ? (
                        <div>
                          <Button loading={createInquiryLogin} danger type="primary" onClick={()=>createInquiry()}>立即询价</Button>
                        </div>
                      ): null
                    } */}
                    <div>
                      <Button ghost type="primary" style={{marginRight: 10}} onClick={()=>addShopcart(false)}>{intl.formatMessage({ id: "加入购物车" })}</Button>
                      <Button type="primary" onClick={()=>addShopcart(true)}>{intl.formatMessage({ id: "立即购买" })}</Button>
                    </div>
                  </div>
                </div>
                {
                  data.goods.data[buyActive.index].type !== 'inquiry' ? (
                    <div className={styles.attr}>
                      {
                        data.goods.data[buyActive.index].type === 'dms' ? (
                          supportBuy.stockData ? (
                            supportBuy.stockData.map((stock: any, index: number)=>{
                              return (
                                stock.val ? (
                                  <div className={styles.item} key={index}>
                                    <label>{stock.key}</label>
                                    <div>{stock.val}</div>
                                  </div>
                                ): null
                              )
                            })
                          ): null
                        ): (
                          <>
                            {
                              Object.keys(data.goods.data[buyActive.index].product_params).map((key, index)=>{
                                return (
                                  <div className={styles.item} key={index}>
                                    <label>{key}</label>
                                    <div>{data.goods.data[buyActive.index].product_params[key]}</div>
                                  </div>
                                )
                              })
                            }
                            {
                              data.goods.data[buyActive.index].warranty ? (
                                <div className={styles.item}>
                                  <label>{intl.formatMessage({ id: "质保时间" })}</label>
                                  <div>{data.goods.data[buyActive.index].warranty}</div>
                                </div>
                              ): null
                            }
                            {
                              data.goods.data[buyActive.index].after_service ? (
                                <div className={styles.item}>
                                  {/* <label>备注</label> */}
                                  <div>{data.goods.data[buyActive.index].after_service}</div>
                                </div>
                              ): null
                            }
                          </>
                          
                        )
                      }
                    </div>
                  ): null
                }
              </div>
            ): null
          }
          {
            data.price.data && data.price.data.length ? (
              <div className={styles.box} id="price">
                <h5>
                  {intl.formatMessage({ id: "4S价格" })}
                  {/* {
                    brandCode === 'benz' ? (
                      <span>
                        奔驰原厂大库订货 低至 8.5折 实时价格更新 <button onClick={()=>setGoodsVisible(true)}>立即订货</button>
                      </span>
                    ): null
                  } */}
                </h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: "厂商" })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: "说明" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "进价(未含税)" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "进价(含税)" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "销售价(含税)" })}</th>
                      {/* <th style={{width: '17%', textAlign: 'center', color: '#2b99ff'}}>大库价格（含税）</th> */}
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "更新时间" })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.price.data?.map((item: any, index: number)=>{
                        return (
                          <tr key={index}>
                            <td>{item.mill}</td>
                            <td>{item.remark}</td>
                            <td>{item.eot_price ? `￥${item.eot_price}` : ''}</td>
                            <td>{item.cost_price ? `￥${item.cost_price}` : ''}</td>
                            <td>{item.sale_price ? `￥${item.sale_price}` : ''}</td>
                            {/* <td style={{color: '#2b99ff'}}>
                              {
                                supportBuy.DMS?.name === item.name ? (
                                  supportBuy.stockData ? (
                                    <Popover content={(
                                      <div className={styles.stockData}>
                                        {
                                          supportBuy.stockData.map((stock: any, i: number)=>{
                                            return (
                                              stock.val ? (
                                                <span key={i}>{stock.key}: {stock.val}</span>
                                              ): null
                                            )
                                          })
                                        }
                                      </div>
                                    )}>
                                      {supportBuy.DMS.purchase_price ? `￥${supportBuy.DMS.purchase_price/100}` : ''} 
                                      <i className='iconfont icon-bianji2'></i>
                                    </Popover>
                                  ): (
                                    supportBuy.DMS.purchase_price ? `￥${supportBuy.DMS.purchase_price/100}` : ''
                                  )
                                ): null
                              }
                            </td> */}
                            <td>
                              {/* {
                                supportBuy.DMS?.name === item.name ? (
                                  <>{supportBuy.DMS.update_date}</>
                                ): (
                                  <>{item.vaild_date}</>
                                )
                              } */}
                              {item.vaild_date}
                            </td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            ): null
          }
          {
            data.replace?.data?.directly.length ? (
              <div className={styles.box} id="replace">
                <h5>{intl.formatMessage({ id: "替换件" })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: "品牌" })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>OE</th>
                      <th style={{width: '20%', textAlign: 'center'}}>{intl.formatMessage({ id: "零件名称" })}</th>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: "件数" })}</th>
                      <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: "备注" })}</th>
                      <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: "销售价" })}</th>
                    </tr>
                  </thead>
                </table>
                {
                  data.replace?.data?.directly.length ? (
                    <table>
                      <caption>{intl.formatMessage({ id: "直接替换" })}</caption>
                      <tbody>
                        {
                          data.replace.data.directly.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>
                                  {/* {item.brandcn} */}
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.brandcn}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} /></p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i:number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
                {
                  data.replace?.data?.indirect.length ? (
                    <table>
                      <caption>{intl.formatMessage({ id: "间接替换" })}</caption>
                      <tbody>
                        {
                          data.replace.data.indirect.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} /></p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
              </div>
            ): null
          }
          {
            data.stocks.data?.length ? (
              <div className={styles.box} id="stocks">
                <h5>{intl.formatMessage({ id: "在线库存" })}</h5>
                <div className={styles.stocks}>
                  {
                    data.stocks.data?.map((item: any, index: number)=>{
                      return (
                        <div className={styles.item} key={index}>
                          <div className={styles.flex}>
                            <h6>{item.quality_name}</h6>
                            <em>{item.sale_price ? (item['unit'] ? item['unit'] : '￥') : ''}{item.sale_price}</em>
                          </div>
                          <div className={styles.flex}>
                            <p style={{width: '60%'}}>{item.company_name}</p>
                            <p>
                              {item.city.length > 4 ? item.city.substr(0, 4) : item.city}{item.city.length > 4 ? '…' : ''}
                            </p>
                            {/* <span className="zhb-update-time">{item.update_time}</span> */}
                          </div>
                          <div className={styles.info}>
                            {
                              item.company_name ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: "公司：" })}</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.company_name}</span>
                              </div> : null
                            }
                            {
                              item.province || item.city || item.area ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: "地址：" })}</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.province}{item.city}{item.area}</span>
                              </div> : null
                            }
                            {
                              item.phone ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: "电话" })}</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.phone}</span>
                              </div> : null
                            }
                            {
                              item.wechat ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>{intl.formatMessage({ id: "微信" })}</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.wechat}</span>
                              </div> : null
                            }
                            {
                              item.qq ? <div>
                                <span style={{fontSize: 12, fontWeight: 500}}>Q&nbsp;&nbsp;Q：</span>
                                <span style={{fontSize: 12, fontWeight: 400}}>{item.qq}</span>
                              </div> : null
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ): null
          }
          {
            data.articles.data?.length ? (
              <div className={styles.box} id="articles">
                <h5>{intl.formatMessage({ id: "知名品牌" })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: "图片" })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: "品牌编号" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "零件名称" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "品牌" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "型号" })}</th>
                      <th style={{width: '17%', textAlign: 'center'}}>{intl.formatMessage({ id: "注释" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "参考价格" })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.articles.data?.map((item: any, index: number)=>{
                        return (
                          <tr key={index}>
                            <td>
                              {
                                item.images ? (
                                  <img src={ item.images } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.normalize_article_number}</td>
                            <td>{item.label}</td>
                            <td>
                              {
                                item.brand_logo ? (
                                  <img src={ item.brand_logo } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.Status}</td>
                            <td>{item.description}</td>
                            <td>{item.price}</td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            ): null
          }
          {
            data.imported.data?.length ? (
              <div className={styles.box} id="imported">
                <h5>{intl.formatMessage({ id: "进口品牌" })}</h5>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: "图片" })}</th>
                      <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: "品牌编号" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "零件名称" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "品牌" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "型号" })}</th>
                      <th style={{width: '17%', textAlign: 'center'}}>{intl.formatMessage({ id: "注释" })}</th>
                      <th style={{width: '12%', textAlign: 'center'}}>{intl.formatMessage({ id: "参考价格" })}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.imported.data?.map((item: any, index: number)=>{
                        return (
                          <tr key={index}>
                            <td>
                              {
                                item.images ? (
                                  <img src={ item.images } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.normalize_article_number}</td>
                            <td>{item.label}</td>
                            <td>
                              {
                                item.brand_logo ? (
                                  <img src={ item.brand_logo } style={{width: "44px"}} />
                                ): null
                              }
                            </td>
                            <td>{item.Status}</td>
                            <td>{item.description}</td>
                            <td>{item.price}</td>
                          </tr>
                        )
                      })
                    }
                    
                  </tbody>
                </table>
              </div>
            ): null
          }
          {
            data.applicable.data?.data?.length ? (
              <div className={styles.box} id="applicable">
                <h5>{intl.formatMessage({ id: "适用车型" })}</h5>
                <div className={styles.tabBrand}>
                  <h6>{intl.formatMessage({ id: "品牌" })}</h6>
                  <ul>
                    {
                      data.applicable.data?.title.map((item: any, index: number)=>{
                        return (
                          <li key={index} onClick={()=>setTabBrandActive(item[0])} className={tabBrandActive === item[0] ? styles.active : ''}>{item[1]}</li>
                        )
                      })
                    }
                  </ul>
                </div>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: '15%'}}>{intl.formatMessage({ id: "品牌" })}</th>
                      <th style={{width: '35%'}}>{intl.formatMessage({ id: "车型" })}</th>
                      <th style={{width: '15%'}}>{intl.formatMessage({ id: "年份" })}</th>
                      <th style={{width: '20%'}}>{intl.formatMessage({ id: "市场" })}</th>
                      <th style={{width: '15%'}}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.applicable.data?.data.map((item: any, index: number)=>{
                        return (
                          tabBrandActive === 'All' || tabBrandActive === item.uri_param.brandCode ? (
                            <tr key={index}>
                              <td>{item.brandname}</td>
                              <td>{item.cars_model}</td>
                              <td>{item.year}</td>
                              <td>{item.market}</td>
                              <td>
                                {
                                  queryType === 'part_search' ? (
                                    <Button type='link' onClick={()=>setPartSearchActive(item.uri_param)}>{intl.formatMessage({ id: "查看" })}</Button>
                                  ): null
                                }
                              </td>
                            </tr>
                          ): null
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            ): null
          }
        </div>
      </div>
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partSearchActive.p}
            mcid={partSearchActive.mcid}
            brandcode={partSearchActive.brandCode}
            title={partSearchActive.title}
            cancelFn={cancelFn}
          />
        ): null
      }
      {/* {
        goodsVisible ? <Goods basic={supportBasic} supportBuy={supportBuy} refreshPrice={buyPartPrice} goodsLoading={goodsLoading} setGoodsVisible={setGoodsVisible} /> : null
      } */}
    </>
  )
}

export default PartDetail