import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PartDetail } from '@/pages/Epc'
import { getUrlParams } from '@/utils/utils'
import partApi from '@/api/epcApi'
import styles from './vin.module.scss'
import { Button, Spin } from 'antd'
import MainContext from '@/stores/stores'
import { useIntl } from 'react-intl'


const PartSearchPage: React.FC = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const { pathname } = useLocation()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { pageType } = commonStore
  const { pid, brandcode } = getUrlParams()
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ active, setActive ] = useState<any>({})
  const [ data, setData ] = useState<any>([])
  const [ brandData, setBrandData ] = useState<any>([])
  const [ type, setType ] = useState<string>('part')
  const [ acronymActive, setAcronymActive ] = useState<string>('全部')
  
  useEffect(()=>{
    if(pid){
      if(brandcode){
        setActive({pid: pid, brandcode: brandcode})
      }else {
        partSearch()
      }
    }
  }, [])

  useEffect(()=>{
    if(active.pid){
      setType('detail')
      if(pathname !== '/oe/part') {
        navigate(`/epc/part?pid=${active.pid}&brandcode=${active.brandcode}`)
      }
      
    }
  }, [active])

  const partSearch = () => {
    setLoading(true)
    partApi.partSearch({pid}).then((result: any)=>{
      setLoading(false)
      if(result.code === 1){
        let partData: any = []
        let brands: any = []
        result.data.forEach((item: any, index: number)=>{
          item.brand_list.forEach((brand: any, i: number)=>{
            brand.parentIndex = index
            brand.index = i
            brand.label = item.label
            brand.pid = item.pid
            brand.pidHtml = item.pid
            brand.remark = item.remark
            brand.has_replace = item.has_replace
            brand.engpid = item.engpid
            brand.engpidHtml = item.engpid
            let reg = RegExp('('+pid+')')
            brand.pidHtml = brand.pidHtml.replace(reg, '<span style="color: #1890ff">$1</span>')
            item.pidHtml = item.pid.replace(reg, '<span style="color: #1890ff">$1</span>')
            partData.push(brand)
            if(brands.indexOf(brand.name) === -1){
              brands.push(brand.name)
            }
          })
        })
        brands.unshift('全部')
        setAcronymActive('全部')
        setBrandData(brands)
        result.data.forEach((item: any, index: number)=>{
          let reg = RegExp('('+pid+')')
          item.pidHtml = item.pid.replace(reg, '<span style="color: #1890ff">$1</span>')
        })
        setData(partData)
        if(partData.length === 1){
          const item = partData[0]
          setActive({pid: item.pid, brandcode: item.brandCode})
        }
      }
    })
  }
  
  const backPart = () => {
    if(pathname !== '/oe/part') {
      navigate(`/epc/part?pid=${active.pid}`)
    }
    setType('part')
    setActive({})
  }
  return (
    <>
      {
        type === 'part' ? (
          <div className={styles.part}>
            <div className={styles.top}>
              <h6>{intl.formatMessage({ id: "品牌" })}：</h6>
              <ul>
                {
                  brandData.map((item: string, index: number)=>{
                    return (
                      <li onClick={()=>setAcronymActive(item)} key={index} className={acronymActive === item ? styles.active : ''}>{item}</li>
                    )
                  })
                }
              </ul>
            </div>
            <div className={styles.partList}>
              <table className={styles.tableHeader}>
                <thead>
                  <tr>
                    <th style={{width: '16%'}}>{intl.formatMessage({ id: "零件号" })}</th>
                    <th style={{width: '14%'}}>{intl.formatMessage({ id: "品牌" })}</th>
                    <th style={{width: '20%'}}>{intl.formatMessage({ id: "名称" })}</th>
                    <th style={{width: '10%'}}>{intl.formatMessage({ id: "进价（含税）" })}</th>
                    <th style={{width: '10%'}}>{intl.formatMessage({ id: "渠道价格（含税）" })}</th>
                    <th style={{width: '30%'}}>{intl.formatMessage({ id: "备注" })}</th>
                  </tr>
                </thead>
              </table>
              <div className={styles.tableBody} style={{ height: `calc(100vh - ${pageType === '2' ? '192' : '272' }px)` }}>
                <Spin spinning={loading}>
                  <table>
                    <tbody>
                      {
                        data.map((item: any, index: number)=>{
                          return (
                            acronymActive === '全部' || acronymActive === item.name ? (
                              <tr onClick={()=>setActive({pid: item.pid, brandcode: item.brandCode})}>
                                <td style={{width: '16%'}}>
                                  {
                                    item.engpid ? (
                                      <>
                                        <span style={{display: 'inline-block'}} dangerouslySetInnerHTML={{ __html: item.pidHtml }} /> <span style={{display: 'inline-block'}} dangerouslySetInnerHTML={{ __html: item.engpidHtml }} />
                                      </>
                                    ) : <span dangerouslySetInnerHTML={{ __html: item.pidHtml }} />
                                  }
                                </td>
                                <td style={{width: '14%'}}>{item.name}</td>
                                <td style={{width: '20%'}}>{item.label}</td>
                                <td style={{width: '10%'}}>{item.cost_price}</td>
                                <td style={{width: '10%'}}>{item.price}</td>
                                <td style={{width: '30%'}}>{item.remark}</td>
                              </tr>
                            ): null
                          )
                        })
                      }
                    
                    </tbody>
                  </table>
                  {
                    !loading && !data.length ? (
                      <div className={styles.nodata}>没有找到 {pid} 相关零件</div>
                    ): null
                  }
                </Spin>
              </div>
            </div>
          </div>
        ): null
      }
      {
        type === 'detail' ? (
          <div className={styles.part}>
            {
              data.length ? (
                <Button onClick={()=>backPart()} size='small' className={styles.back}>返回</Button>
              ): null
            }
            <PartDetail pid={active.pid} brandCode={active.brandcode} queryType='part_search' />
          </div>
        ): null
      }
    </>
  )
}

export default PartSearchPage