import React, { useState, useEffect, useRef } from 'react'
import css from './exploded.module.scss'

interface Parameter {
  data: any,
  activeHsKey: string,
  activePart: any,
  getSvgImgRect: () => void,
  hsKeyAnchor: (val: string) => void
}

let zoomOriginal = {
  width: 0,
  height: 0,
  zoomNum: 0
}
let position = {
  startX: 0,
  startY: 0,
  tx: 0,
  ty: 0
}
let zoomNum = 0

const Lite: React.FC<Parameter> = (props: Parameter) => {
  const imgBoxRef = useRef<HTMLDivElement>(null)
  const svgBoxRef = useRef<HTMLDivElement>(null)

  const [ imgShow, setiImgShow ] = useState<boolean>(false)

  useEffect(()=>{
    const onWindowResize = zoom.bind(this, 'original')
    window.addEventListener( 'resize', onWindowResize )
    let img = document.createElement("img")
    img.src = props.data.img.imagePath
    img.onload = () => {
      setTimeout(()=>{
        if(svgBoxRef.current){
          svgBoxRef.current.style.width = img.naturalWidth + 'px'
          svgBoxRef.current.style.height = img.naturalHeight + 'px'
          zoomOriginal.width = img.naturalWidth
          zoomOriginal.height = img.naturalHeight
          setiImgShow(true)
          initSvg()
        }
      }, 300)
        // initSvg()
      
    }
    return () => {
      window.removeEventListener( 'resize', onWindowResize )
    }
  }, [])
  const initSvg = () => {
    if(svgBoxRef.current){
      const svgImgRect: any = props.getSvgImgRect()
      let top = Math.floor((zoomOriginal.height - svgImgRect.height) / 2)
      let left = Math.floor((zoomOriginal.width - svgImgRect.width) / 2)
      zoomNum = (Number((svgImgRect.height / zoomOriginal.height).toFixed(2)))
      zoomOriginal.zoomNum = zoomNum
      svgBoxRef.current.style.top = -top + 'px'
      svgBoxRef.current.style.left = -left + 'px'
      svgBoxRef.current.style.transform = `scale(${zoomNum})`
    }
  }

  const setSvgMove = (e: {pageX: number, pageY: number}) => {
    position.startX = e.pageX - position.tx
    position.startY = e.pageY - position.ty
    document.addEventListener("mousemove", svgMove)
    document.addEventListener("mouseup", () => {
      document.removeEventListener("mousemove", svgMove);
    })
  }

  const svgMove = (e: {pageX: number, pageY: number}) => {
    let tx = e.pageX - position.startX;
    let ty = e.pageY - position.startY;
    position.tx = tx
    position.ty = ty
    const transformStr = `translate(${tx}px,${ty}px)`;
    if(imgBoxRef.current){
      imgBoxRef.current.style.transform = transformStr
    }
  }
  

  const zoom = (type: string) => {
    if(type === 'add' && zoomNum < 1){
      zoomNum += 0.1
    }
    if(type === 'minus' && zoomNum > 0.1){
      zoomNum -= 0.1
    }
    if(type === 'original'){
      zoomNum = zoomOriginal.zoomNum
      if(imgBoxRef.current){
        position.tx = 0
        position.ty = 0
        const transformStr = `translate(0px,0px)`;
        imgBoxRef.current.style.transform = transformStr
      }
      initSvg()
    }
    if(svgBoxRef.current){
      svgBoxRef.current.style.transform = `scale(${zoomNum})`
    }
  }
  return (
    <div className={css['lite']}>
      <div ref={imgBoxRef} onMouseDown={(e)=>setSvgMove(e)}>
        <div ref={svgBoxRef} className={css['svg-box']}>
          {
            props.data.img && props.data.img.imagePath && imgShow ? (
              <img src={props.data.img.imagePath} />
            ): null
          }
          {
            props.data.img && props.data.img.imgPoints && props.data.img.imgPoints.hotspots ? (
              <svg>
                {
                  props.data.img.imgPoints.hotspots.map((item: any, index: number)=>{
                    return (
                      <rect
                        key={index}
                        x={item.hsX} 
                        y={item.hsY} 
                        width={item.maxX - item.hsX + ''} 
                        height={item.maxY - item.hsY + ''} 
                        fill={"rgba(0, 0, 0, 0)"}
                        className={props.activeHsKey === item.hsKey || props.activePart.pnum === item.hsKey || props.activePart.hsKey === item.hsKey ? css['active'] : ""}
                        onClick={()=>props.hsKeyAnchor(item.hsKey)}
                      />
                    )
                  })
                }
              </svg>
            ): null
          }
        </div>
      </div>
      <div className={css['img-tools']}>
        <span>
          <i className="iconfont icon-fangda1" onClick={()=>zoom('add')} />
          <u />
          <i className="iconfont icon-suoxiao1" onClick={()=>zoom('minus')} />
        </span>
        <span>
          <i className="iconfont icon-zhongzhi" onClick={()=>zoom('original')} />
        </span>
      </div>
    </div>
  )
}

export default Lite;