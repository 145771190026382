import React, { createContext } from 'react'

interface context {
  commonStore: any,
  setCommonStore: any
}

const MainContext= createContext<context>({
  commonStore: {},
  setCommonStore: ()=>{}
})

export default MainContext