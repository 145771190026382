import React, { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import MainContext from '@/stores/stores'
import { message, Modal, Spin, Tooltip } from "antd"
import Exploded, { Lite } from '@/components/Exploded'
import Copy from '@/components/Copy'

import { PartDetail, SearchPartInput, PartSearch, Breadcrumb } from '@/pages/Epc'
import { cdnsImgPath } from '@/utils/utils'
import axios from 'axios'
import vinApi from '@/api/epcApi'
import listApi from '@/api/listApi'
import { useIntl } from 'react-intl'
import accountApi from '@/api/accountApi'

import css from './vin.module.scss'
import styles from './partspriceNew.module.scss'

interface PropsIn {
  brandcode: string,
  mcid: string,
  num: string,
  update?: Function,
  vin?: string,
  index?: string,
  subindex?: string,
  type?: string,
  subgroup?: string,
  mid?: string,
  pid?: string,
  finalid?: string,
  backFn?: Function
}

let getPartUpload = false
let getPartTag = false
let is_cst = 0

const VinDetail: React.FC<PropsIn> = (props) => {
  const navigate = useNavigate();
  const { vin = '', brandcode, mcid, num, index, subindex, type, subgroup, mid = '', pid, backFn, finalid } = props
  const intl = useIntl()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { crumb, vinData, pageType, userInfo } = commonStore

  const boxContentRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  let tempPart = useRef<any>(null)
  
  const [ structure, setStructure ] = useState<any>(JSON.parse(sessionStorage.getItem('structure') || '[]'))
  const [ subGroup, setSubGroup ] = useState<any>(JSON.parse(sessionStorage.getItem('subGroup') || '[]'))

  const [ data, setData ] = useState<any>({})
  const [ activeHsKey, setActiveHsKey ] = useState<string>('')

  const [ cntIndex, setCntIndex ] = useState<any>([])
  const [ partKey, setPartKey ] = useState<string>('')
  
  const [ activePart, setActivePart ] = useState<any>({})
  const [ detailPart, setDetailPart ] = useState<any>({})
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ config, setConfig ] = useState<any>([])
  
  const [ scrollTo, setScrollTo ] = useState<boolean>(true)
  const [ partDetailVisible, setPartDetailVisible ] = useState<boolean>(false)

  const [ rpVisible, setRpVisible  ] = useState<boolean>(false)
  const [ rpLoading, setRpLoading] = useState<boolean>(false)
  const [ replaceData, setReplaceData ] = useState<any>({})

  const [ searchConfigKey, setSearchConfigKey ] = useState<string>('')
  const [ typeActive, setTypeActive ] = useState<string>('')
  const [ quantity, setQuantity ] = useState<number>(0)

  const [salePrices, setSalePrices] = useState<any>({})
  
  
  useEffect(()=>{
    if(type === 'partSearch' || (!subindex && structure.length && decodeURIComponent(structure[0].mcid) === mcid) || (subindex && subGroup.length && decodeURIComponent(subGroup[0].mcid) === mcid)){
      if(finalid){
        getMaintenancePart()
      }else {
        getPart()
      }
    }else {
      getStructure()
    }
  }, [])

  useEffect(()=>{
    if(activeHsKey){
      scrollToAnchor()
    }
  }, [activeHsKey])

  useEffect(()=>{
    if(pid && data.data && scrollTo){
      setScrollTo(false)
      scrollToAnchor()
    }
  }, [activePart])

  useEffect(()=>{
    if(getPartUpload){
      if(subindex){
        getSubGroup()
      }else {
        getPart()
      }
    }
  }, [structure])

  useEffect(()=>{
    if(getPartUpload){
      getPart()
    }
  }, [subGroup])

  useEffect(()=>{
    if(getPartTag){
      tag()
    }
  }, [data])

  useEffect(()=>{
    if(tempPart.current){
      setDetailPart(tempPart.current)
    }
  }, [quantity])

  useEffect(()=>{
    if(tempPart.current){
      setQuantity(parseInt(tempPart.current.quantity || 1))
    }
  }, [typeActive])
  
  useEffect(()=>{
    if(detailPart.pid){
      setPartDetailVisible(true)
    }
  }, [detailPart])

  useEffect(()=>{
    if(configVisible){
      searchConfig()
    }
  }, [configVisible])

  const [unit, setUnit] = useState<string>("￥")

  const getSalePrices = (pid: string) => {
    // if(!is_cst) {
    //   return false
    // }
		accountApi.getUserInfo().then((res: any) => {
		  if(res.code === 1) {
		    let repair_shop_id = res.data.repair_shop_id
				let params = {
					oes: pid,
					status: 1,
					repair_shop_id: repair_shop_id
				}
				axios.post("/v1/api/web/oe/price", params).then((res: any) => {
					if(res.code === 1) {
						let _salesPrice: any = {}
						res.data.forEach((item: any) => {
							_salesPrice[item.oe] = item.price
						})
						console.log(_salesPrice)
						setSalePrices(_salesPrice)
					}
				})
		  }
		})
  }
  
  
  const getStructure = () => {
    const param = {
      vin: vin,
      mcid: mcid,
      brandCode: brandcode,
      num: num
    }
    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1) {
        result.data.forEach((d: any)=>{
          d.img = result.imgs[d.imageLarge] || ''
        })
        getPartUpload = true
        sessionStorage.setItem('structure', JSON.stringify(result.data))
        setStructure(result.data)
      }
    })
  }
  const getSubGroup = () => {
    if(index){
      const item = structure[index]
      const param = {
        vin,
        mcid: item.mcid,
        brandCode: item.brandCode,
        num: item.num,
        subgroup: item.subgroup,
        maingroup: item.maingroup,
        level: 1
      }
      vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
        if(result.code === 1) {
          result.data.forEach((d: any)=>{
            d.img = result.imgs[d.imageLarge] || ''
          })
          getPartUpload = true
          sessionStorage.setItem('subGroup', JSON.stringify(result.data))
          setSubGroup(result.data)
        }
      })
    }
  }
  const getPart = () => {
    let param: any = {}
    if(type === 'partSearch'){
      param = {
        vin,
        brandCode: brandcode,
        mcid: mcid,
        num: num,
        subgroup: subgroup,
        mid: mid
      }
    }else if(index) {
      let item = structure[index]
      if(subindex){
        item = subGroup[subindex]
      }
      param = {
        vin,
        brandCode: brandcode,
        mcid: item.mcid,
        num: item.num,
        subgroup: item.subgroup,
        mid: item.mid
      }
    }
    getOldPart(param)
    return
    vinApi.points(param).then((result: any)=>{
      if(result.code === 1 && result.data && result.data.information && result.data.cnts && result.data.cnts !== '[]'){
        
        result.data.information = result.data.information ? JSON.parse(result.data.information) : []
        result.data.cnts = result.data.cnts ? JSON.parse(result.data.cnts) : []
        if(boxContentRef.current){
          result.data.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }

        let imageFormat = 'image/png'
        if(result.data.img_name_simple){
          imageFormat = 'image/' + result.data.img_name_simple.substring(result.data.img_name_simple.lastIndexOf('.') + 1)
        }
        result.data.mask = false
        result.data.information.forEach((item: any, index: number)=>{
          for(let key in result.data.cnts){
            let cnt = result.data.cnts[key]
            // let x = 0
            // let y = 0
            if(cnt.itids.indexOf(item.itid_index) !== -1){
              if(!cnt.point){
                cnt.point = []
              }
              cnt.point.push(index)
              cnt.itid_old = item.itid_old
              result.data.mask = true
            }
            // if(cnt.cnts){
            //   cnt.cnts.forEach((c: any)=>{
            //     if(x === 0 || x > c[0]){
            //       x = c[0]
            //     }
            //     if(y === 0 || y > c[1]){
            //       y = c[1]
            //     }
            //   })
            // }
            // cnt.imgXY = [-x, -y]
          }
        })

        vinApi.partnew(param).then((res: any)=>{
          let itid_list: any = []
          let pid_list: any = []
          let tempDetail = {}

          res.data?.part_detail.forEach((item: any)=>{
            item.forEach((detail: any)=>{
              if(vin){
                detail.vin = vin
              }
              if(detail.pnum){
                itid_list.push(detail.pnum)
              }
              if(detail.pid){
                pid_list.push(detail.pid)
              }
              if(detail.pid === pid){
                tempDetail = detail
              }
              if(!vin){
                detail.colorvalue = 1
              }
              detail.progress = 0

              detail.img = result.data.img_name_simple ? (cdnsImgPath + '/' + result.data.img_name_simple) : result.data.img_name
              
              if(result.data.information){
                result.data.information.forEach((pItem: any)=>{
                  if(pItem.itid_old === detail.pnum && pItem.rect){
                    // detail.rect = pItem.rect
                    detail.img_point = {
                      hotspots: [
                        {
                          "hsX": pItem.rect[0],
                          "hsY": pItem.rect[1],
                          "maxX": pItem.rect[0] + pItem.rect[2],
                          "maxY": pItem.rect[1] + pItem.rect[3],
                          "hsKey": pItem.itid_old
                        }
                      ],
                      maxScaleFactor: "",
                      imageFormat,
                      imageHeight: 1240,
                      imageWidth: 1120
                    }
                  }
                })
              }
            })
          })
          result.data.itid_list = itid_list
          res.points = result.data
          res.pid_list = pid_list
          getPartTag = true
          setData(res)
          setActivePart(tempDetail)
        })
      }else {
        getOldPart(param)
      }
    })
  }
  const getOldPart = (param: any) => {
    vinApi.part(param).then((result: any)=>{
      if(result.code === 1){
        let pid_list: any = []
        if(boxContentRef.current){
          result.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }
        
        result.data.part_detail.forEach((item: any)=>{
          item.forEach((detail: any)=>{

            if(detail.pid){
              pid_list.push(detail.pid)
            }
            if(detail.pid === pid){
              setActivePart(detail)
            }
            if(!vin){
              detail.colorvalue = 1
            }else {
              detail.vin = vin
            }
            if(result.img){
              detail.img = result.img.imagePath
            }
            
            if(result.img && result.img.imgPoints && result.img.imgPoints.hotspots){
              result.img.imgPoints.hotspots.forEach((pItem: any)=>{
                if(pItem.hsKey === detail.pnum){
                  // detail.rect = [pItem.hsX, pItem.hsY, pItem.maxX - pItem.hsX, pItem.maxY - pItem.hsY ]
                  detail.img_point = JSON.parse(JSON.stringify(result.img.imgPoints))
                  detail.img_point.hotspots = [pItem]
                }
              })
            }
          })
        })
        result.pid_list = pid_list
        getPartTag = true
        if(pid_list && pid_list.length) {
        	let _pid_list: any = []
        	result.data.part_detail.map((item: any) => {
        		item.forEach((part: any) => {
        			if(part.colorvalue) {
        				if(part.pid) {
        					_pid_list.push(part.pid)
        				}
        			}
        		})
        	})
          getSalePrices(_pid_list.join(","))
        }
        setData(result)
        
      }
    })
  }
  const getMaintenancePart = () => {
    let item = structure[index || 0]
    const pointParam = {
      brandCode: brandcode,
      mcid: mcid,
      final_id: finalid
    }
    let partParam: any = {
      brandCode: brandcode,
      mcid: mcid,
      vin: vin,
      sub_name: item.standard_subgroup_name,
      label_list: []
    }
    if(item.label_list){
      item.label_list.forEach((label: any)=>{
        partParam.label_list.push(label.standard_label)
      })
      partParam.label_list = partParam.label_list.join(',')
    }
    
    axios.all([vinApi.getMaintenancePoint(pointParam), vinApi.getMaintenancePart(partParam)]).then(axios.spread((pointResult, partResult) => { 
      if(pointResult.code === 1 && partResult.code === 1){
        pointResult.data.imagePath = pointResult.data.img_url
        let pid_list: any = []
        let result: any = {
          data: {
            part_detail: partResult.data
          },
          img: pointResult.data
        }
        if(boxContentRef.current){
          result.svgImgRect = boxContentRef.current.getBoundingClientRect()
        }
        
        result.data.part_detail.forEach((detail: any, index: number)=>{
          if(detail.pid){
            pid_list.push(detail.pid)
          }
          if(detail.pid === pid){
            setActivePart(detail)
          }
          detail.colorvalue = 1
          if(result.img){
            detail.img = result.img.imagePath
          }
          if(result.img && result.img.imgPoints && result.img.imgPoints.hotspots){
            result.img.imgPoints.hotspots.forEach((pItem: any)=>{
              if(pItem.hsKey === detail.hsKey){
                // detail.rect = [pItem.hsX, pItem.hsY, pItem.maxX - pItem.hsX, pItem.maxY - pItem.hsY ]
                detail.img_point = JSON.parse(JSON.stringify(result.img.imgPoints))
                detail.img_point.hotspots = [pItem]
              }
            })
          }
          result.data.part_detail[index] = [detail]
        })
        result.pid_list = pid_list
        getPartTag = true
        if(pid_list && pid_list.length) {
					let _pid_list: any = []
					result.data.part_detail.map((item: any) => {
						item.forEach((part: any) => {
							if(part.colorvalue) {
								if(part.pid) {
									_pid_list.push(part.pid)
								}
							}
						})
					})
          getSalePrices(_pid_list.join(","))
        }
        setData(result)
      }
    }))
  }
  const anchor = (item: any) => {
    setActivePart(item)
    setActiveHsKey('')
    if(data.points){
      data.points.information.forEach((pItem: any)=>{
        if(pItem.itid_old === item.itid){
          setActivePoint(pItem, true)
        }
      })
    }
  }
  
  const scrollToAnchor = () => {
    if(listRef.current){
      let anchorElement = listRef.current.getElementsByClassName("svg-part-active")[0]
      if(anchorElement) { anchorElement.scrollIntoView() }
    }
    
  }

  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
      if(index){
        let indexTemp: number[] = []
        let i = parseFloat(index)
        if(subindex){
          subGroup.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }else {
          structure.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }
        
        if(indexTemp.indexOf(i) === -1){
          i = indexTemp[0]
          let item = structure[i]
          if(subindex){
            item = subGroup[i]
          }
          const param = `vin=${vin}&brandcode=${brandcode}&mcid=${item.mcid}&index=${i}`
          navigate(`/epc/vin/detail?${param}`)
          if(props.update){
            props.update()
          }
        }
      }
    }
  }
  const setGroup = (type: string) => {
    
    if((structure.length && index) && (( subGroup.length && subindex) || !subindex)){
      let i = parseFloat(index)
      if(subindex){
        i = parseFloat(subindex)
      }
      if(filter){
        if(type === 'before'){
          if(i){
            i--
          }
        }
        if(type === 'next'){
          if(subindex){
            if(i < subGroup.length){
              i++
            }
          }else {
            if(i < structure.length){
              i++
            }
          }
          
        }
      }else {
        let indexTemp: number[] = []
        if(subindex){
          subGroup.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }else {
          structure.forEach((item: {colorvalue: string}, index: number)=>{
            if(item.colorvalue){
              indexTemp.push(index)
            }
          })
        }
        
        if(type === 'before'){
          if(indexTemp.indexOf(i) > 0){
            i = indexTemp[indexTemp.indexOf(i) - 1]
          }
        }
        if(type === 'next'){
          if(indexTemp.indexOf(i) < indexTemp.length - 1){
            i = indexTemp[indexTemp.indexOf(i) + 1]
          }
        }
      }
      
      let param = ''
      let item: any = {}
      if(subindex){
        if(i === parseFloat(subindex)){
          message.warning(type === 'before' ? intl.formatMessage({ id: "第一组！" }) : intl.formatMessage({ id: "最后一组！" }))
          return
        }
        item = subGroup[i]
        param = `vin=${vin}&brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${index}&subindex=${i}`
      }else {
        if(i === parseFloat(index)){
          message.warning(type === 'before' ?  intl.formatMessage({ id: "第一组！" }) : intl.formatMessage({ id: "最后一组！" }))
          return
        }
        item = structure[i]
        param = `vin=${vin}&brandcode=${brandcode}&mcid=${item.mcid}&num=${num}&index=${i}`
        if(finalid){
          param += `&finalid=${item.final_id}`
        }
      }
      item.type = 'detail'
      item.index = crumb[crumb.length - 1].index
      crumb.pop()
      crumb.push(item)
      setCommonStore({value: crumb, type: 'crumb'})
      window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
      
      let url = `/epc/vin/detail?${param}`
      if(!vin){
        url = `/epc/group/detail?${param}`
      }
      navigate(url)
      if(props.update){
        props.update()
      }
    }
  }
  const searchConfig = (key_words?: string) => {
    let api = vinApi.config
    if(key_words){
      api = vinApi.configSearch
    }
    api({vin, brandCode: brandcode, key_words}).then((result: {code: number, data: any})=>{
      if(result.code === 1){
        setConfig(result.data.subdata)
      }else {
        setConfig([])
      }
    })
  }
  const back = () => {
    let param = `vin=${vin}&num=${num}`
    if(!vin){
      param = `mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${num}`
    }else if(vinData.data) {
      param += `&vinmcid=${mcid.replace(/\=/g, '%3D')}&vincode=${brandcode}`
    }
    if(subindex){
      param += `&index=${index}`
    }

    let url = `/epc/vin?${param}`
    if(!vin){
      url = `/epc/group?${param}`
    }
    let groupCrumb = window.sessionStorage.getItem('groupCrumb') || '[]'
    window.sessionStorage.setItem('crumb', groupCrumb)
    navigate(url)
  }
  
  const tag = () => {
    let tempData = JSON.parse(JSON.stringify(data))
    let param = {
      vin,
      brandCode: brandcode,
      pid_list: tempData?.pid_list?.join(',')
    }
    getPartTag = false
    axios.all([vinApi.tag(param), vinApi.getStandardLabel({brandCode: brandcode, pids: param.pid_list})]).then(axios.spread((result, re) => { 
      let pid_list: any = []
      tempData.data?.part_detail.forEach((item: any)=>{
        item.forEach((detail: any)=>{
          if(result.code === 1 && result.data[detail.pid]){
            detail.tag = result.data[detail.pid]
          }
          if(re.code === 1 && re.data[detail.pid]){
            detail.standardLabel = re.data[detail.pid].standard_label
          }
          if(detail.pid && !pid_list.includes(detail.pid)) {
            pid_list.push(detail.pid)
          }
        })
      })
      setData(tempData)
    }))

    

  }
  const getStandardLabel = () => {
    let tempData = JSON.parse(JSON.stringify(data))

  }
  const hsKeyAnchor = (val: string) => {
    setActivePart({})
    setActiveHsKey(val)
  }
  const setPoints = (points: any) => {
    let temp = JSON.parse(JSON.stringify(data))
    temp.points = points
    setData(temp)
  }

  const setActivePoint = (item: any, isKey?: boolean) => {
    let cntIndex = item.marks || []
    if(item.itids){
      cntIndex = [item.mark]
    }
    setCntIndex(cntIndex)
    if(!isKey){
      hsKeyAnchor(item.itid_old)
    }
    
  }
  

  const replace = (param: {pid: string, brandCode: string}) => {
    setReplaceData({})
    setRpVisible(true)
    setRpLoading(true)
    vinApi.replace(param).then((result: any)=>{
      setRpLoading(false)
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        
        setReplaceData({directly, indirect})
      }
    })
  }
  
  const getSvgImgRect = () => {
    let svgImgRect = {}
    if(boxContentRef.current){
      svgImgRect = boxContentRef.current.getBoundingClientRect()
    }
    return svgImgRect
  }

  
  useEffect(() => {
    getIsCst()
  },[])
  const getIsCst = () => {
    accountApi.getIsCst().then((res: any) => {
      if(res.code === 1) {
        is_cst = res.data.is_cst
      }
    })
  }
  return (
    <>
      {
        type !== 'partSearch' ? <Breadcrumb /> : null
      }
      
      <div className={!type ? css['vin'] : css['vin'] + ' ' + css['no-mg']}>
        <div className={css['l']}>
          <div className={css['box']}>
            <div className={css['box-top']}>
              <h2>{data.data && data.data.mid}</h2>
              {/* <div className={css['box-top-tools']}>
                <span>
                  <input type="text" placeholder="Maingroup Search" />
                  <i className="iconfont iconsousuo" />
                </span>
              </div> */}
            </div>
            <div ref={boxContentRef} className={css['box-content']} style={{ height: `calc(100vh - ${pageType === '2' ? '172' : '252'}px)` }}>
              {
                data.svgImgRect ? (
                  <Lite data={data} getSvgImgRect={getSvgImgRect} activeHsKey={activeHsKey} activePart={activePart} hsKeyAnchor={hsKeyAnchor} />
                ): null
              }
              {
                data.points ? (
                  <Exploded data={data.points} getSvgImgRect={getSvgImgRect} setPoints={setPoints} cntIndex={cntIndex} setActivePoint={setActivePoint} activeHsKey={activeHsKey} activePart={activePart} />
                ): null
              }
            </div>
          </div>
        </div>
        <div className={css['r']}>
          <div className={css['box']}>
            <div className={css['box-top']}>
              <h2>{intl.formatMessage({ id: "列表" })}</h2>
              <div className={css['box-top-tools']}>
                {
                  !type ? (
                    <>
                      <button onClick={()=>back()} className={css['other-r']}><i className="iconfont icon-left" />{intl.formatMessage({ id: "返回" })}</button>
                      <button onClick={()=>setGroup('before')}><i className="iconfont icon-shangyifenzu" /></button>
                      <em>{intl.formatMessage({ id: "切换" })}</em>
                      <button onClick={()=>setGroup('next')}><i className="iconfont icon-xiayifenzu" /></button>

                      {
                        vin && !finalid ? (
                          <>
                            <button onClick={()=>setConfigVisible(true)} className={css['other']}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} />{intl.formatMessage({ id: "配置" })}</button>
                            <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont icon-guolv" style={{color: '#616BF1'}} />{filter ? intl.formatMessage({ id: "不过滤" }) : intl.formatMessage({ id: "过滤" })}</button>
                          </>
                        ): null
                      }
                      <div style={{marginLeft: 10}}>
                        <SearchPartInput 
                          mcid={mcid || (data.data && data.data.mcid) }
                          brandcode={brandcode || data.brandCode}
                          vin={vin || ''}  
                        />
                      </div>
                      {/* <span>
                        <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                        <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                      </span> */}
                    </>
                  ): (
                    <>
                      {
                        backFn ? (
                          <button onClick={()=>backFn()} className={css['other']}><i className="iconfont icon-left" />{intl.formatMessage({ id: "返回" })}</button>
                        ): null
                      }
                      {
                        vin ? (
                          <>
                            <button onClick={()=>setConfigVisible(true)} className={css['other']}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} />{intl.formatMessage({ id: "配置" })}</button>
                            <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont icon-guolv" style={{color: '#616BF1'}} />{filter ? intl.formatMessage({ id: "不过滤" }) : intl.formatMessage({ id: "过滤" })}</button>
                          </>
                        ): null
                      }
                    </>
                  )
                }
                
              </div>
            </div>
            <div className={css['box-content']} style={{ height: `calc(100vh - ${pageType === '2' ? '172' : '252'}px)` }}>
              
              {
                finalid ? (
                  <>
                    <table className={css['table-header']}>
                      <thead>
                        <tr>
                          <th style={{width: '14%'}}>{intl.formatMessage({ id: "零件OE号" })}</th>
                          <th style={{width: '15%'}}>{intl.formatMessage({ id: "名称" })}</th>
                          <th style={{width: '12%'}}>{intl.formatMessage({ id: "分解图" })}</th>
                          <th style={{width: '5%'}}>{intl.formatMessage({ id: "件数" })}</th>
                          <th style={{width: '14%'}}>{intl.formatMessage({ id: "位置" })}</th>
                          <th style={{width: '11%'}}>{intl.formatMessage({ id: "备注" })}</th>
                          <th style={{width: '8%'}}>{intl.formatMessage({ id: "参考价格" })}</th>
                          <th style={{width: '8%'}}>{intl.formatMessage({ id: '售价' })}</th>
                          <th style={{width: '13%'}}>{intl.formatMessage({ id: "操作" })}</th>
                        </tr>
                      </thead>
                    </table>
                    <div className={css['list']} ref={listRef}>
                      <table>
                        <tbody>
                          {
                            filter ? (
                              <tr>
                                <td colSpan={8} style={{ textAlign: 'center', color: 'red', fontSize: 13 }}>
                                  *{intl.formatMessage({ id: '红色字体：非此车架号的零件（参照原厂数据）' })}
                                </td>
                              </tr>
                            ): null
                          }
                          {
                            data.data && data.data.part_detail && data.data.part_detail.map((item: any, index: number)=>{
                              return (
                                item.map((part: any, i: number)=>{
                                  let style = ''
                                  
                                  if(activeHsKey){
                                    style = activeHsKey === part.hsKey ? `svg-part-active ${css['svg-part-active']}` : ""
                                    if((!index || activeHsKey !== data.data.part_detail[index-1][0].hsKey ) && activeHsKey === part.hsKey){
                                      style = style + ' ' + css['svg-part-active-top']
                                    }
                                    if((index === data.data.part_detail.length - 1 || activeHsKey !== data.data.part_detail[index+1][0].hsKey) && activeHsKey === part.hsKey){
                                      style = style + ' ' + css['svg-part-active-bottom']
                                    }
                                  }
                                  if(activePart.itid && activePart.pid === part.pid && activePart.itid === part.itid){
                                    style = 'svg-part-active' + ' ' + css['svg-part-active'] + ' ' + css['svg-part-active-top'] + ' ' + css['svg-part-active-bottom']
                                  }
                                  return (
                                    filter || part.colorvalue ? (
                                      <tr key={index + '' + i} className={style} onClick={()=>anchor(part)}>
                                        <td style={{width: '14%', cursor: 'pointer', position: 'relative'}}>
                                          {part.pid}
                                          {
                                            part.pid ? <div style={{position: 'absolute', top: 0, right: 0}}><Copy title={part.pid} noTitle icon /></div> : ''
                                          }
                                        </td>
                                        <td style={{width: '15%'}} dangerouslySetInnerHTML={{__html: part.label}}></td>
                                        <td style={{width: '12%'}}><img src={part.img_url} style={{width: '90%', cursor: 'pointer'}} onClick={()=>{setPartKey(part.pid); setPartSearchkey(partSearchkey + 1)}} /></td>
                                        <td style={{width: '5%'}} dangerouslySetInnerHTML={{__html: part.quantity}}></td>
                                        <td style={{width: '14%'}} dangerouslySetInnerHTML={{__html: part.model}}></td>
                                        <td style={{width: '11%'}} dangerouslySetInnerHTML={{__html: part.remark}}></td>
                                        <td style={{width: '8%'}}>
                                          {part.tag?.sale_price}
                                        </td>
                                        <td style={{ width: '8%' }}>
                                          {part.pid ? (salePrices[part.pid] ? (<span style={{ color: '#ff0000' }}>{unit + salePrices[part.pid]}</span>) : <span style={{ color: '#ff0000' }}>{intl.formatMessage({ id: '待报价' })}</span>) : ''}
                                        </td>
                                        <td style={{width: '13%'}}>
                                          
                                          {
                                            part.pid ? (
                                              <div className={css.operation}>
                                                <Tooltip title={intl.formatMessage({ id: "详情" })}>
                                                  <span className="iconfont icon-xiangqing" style={{fontSize: 14}} onClick={()=>{tempPart.current = part; setTypeActive('basic')}}></span>
                                                </Tooltip>
                                                {
                                                  part.tag?.isreplace ? (
                                                    <Tooltip title={intl.formatMessage({ id: "替换件" })}>
                                                      <span className="iconfont icon-ti" onClick={()=>replace({pid: part.pid, brandCode: brandcode})}></span>
                                                    </Tooltip>
                                                  ): null
                                                }
                                                <Tooltip title={intl.formatMessage({ id: "购物车" })}>
                                                  <span className="iconfont icon-gouwuche1" onClick={()=>{tempPart.current = part; setTypeActive('goods')}}></span>
                                                </Tooltip>
                                              </div>
                                              
                                            ) : null
                                          }
                                        </td>
                                      </tr>
                                    ): null
                                  )
                                })
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                ): (
                  <>
                    <table className={css['table-header']}>
                      <thead>
                        <tr>
                          <th style={{width: '5%'}}>{intl.formatMessage({ id: "位置" })}</th>
                          <th style={{width: '14%'}}>{intl.formatMessage({ id: "零件OE号" })}</th>
                          <th style={{width: '12%'}}>{intl.formatMessage({ id: "名称" })}</th>
                          <th style={{width: '10%'}}>{intl.formatMessage({ id: "标准名称" })}</th>
                          <th style={{width: '5%'}}>{intl.formatMessage({ id: "件数" })}</th>
                          <th style={{width: '14%'}}>{intl.formatMessage({ id: "型号" })}</th>
                          <th style={{width: '11%'}}>{intl.formatMessage({ id: "备注" })}</th>
                          <th style={{width: '8%'}}>{intl.formatMessage({ id: "参考价格" })}</th>
                          <th style={{width: '8%'}}>{intl.formatMessage({ id: '售价' })}</th>
                          <th style={{width: '13%'}}>{intl.formatMessage({ id: "操作" })}</th>
                        </tr>
                      </thead>
                    </table>
                    <div className={css['list']} ref={listRef}>
                      <table>
                        <tbody>
                          {
                            filter ? (
                              <tr>
                                <td colSpan={10} style={{ textAlign: 'center', color: 'red', fontSize: 13 }}>
                                  *{intl.formatMessage({ id: '红色字体：非此车架号的零件（参照原厂数据）' })}
                                </td>
                              </tr>
                            ): null
                          }
                          {
                            data.data && data.data.part_detail && data.data.part_detail.map((item: any, index: number)=>{
                              return (
                                item.map((part: any, i: number)=>{
                                  let style = ''
                                  
                                  if(activeHsKey){
                                    style = activeHsKey === part.pnum ? `svg-part-active ${css['svg-part-active']}` : ""
                                    if((!i || activeHsKey !== item[i-1].pnum || (!filter && !item[i-1].colorvalue)) && activeHsKey === part.pnum){
                                      style = style + ' ' + css['svg-part-active-top']
                                    }
                                    if((i === item.length - 1 || activeHsKey !== item[i+1].pnum || (!filter && !item[i+1].colorvalue)) && activeHsKey === part.pnum){
                                      style = style + ' ' + css['svg-part-active-bottom']
                                    }
                                  }
                                  if(activePart.itid && activePart.pid === part.pid && activePart.itid === part.itid){
                                    style = 'svg-part-active' + ' ' + css['svg-part-active'] + ' ' + css['svg-part-active-top'] + ' ' + css['svg-part-active-bottom']
                                  }
                                  if(!part.colorvalue){
                                    style = style + ' ' + css['filter']
                                  }
                                  return (
                                    filter || part.colorvalue ? (
                                      <tr key={index + '' + i} className={style} onClick={()=>anchor(part)}>
                                        
                                        <td style={{width: '5%'}}>{part.itid}</td>
                                        <td style={{width: '14%', cursor: 'pointer', position: 'relative'}}>
                                          {part.pid}
                                          {
                                            part.pid ? <div style={{position: 'absolute', top: 0, right: 0}}><Copy title={part.pid} noTitle icon /></div> : ''
                                          }
                                        </td>
                                        <td style={{width: '12%'}} dangerouslySetInnerHTML={{__html: part.label}}></td>
                                        <td style={{width: '10%'}} dangerouslySetInnerHTML={{__html: part.standardLabel}}></td>
                                        <td style={{width: '5%'}} dangerouslySetInnerHTML={{__html: part.quantity}}></td>
                                        <td style={{width: '14%'}} dangerouslySetInnerHTML={{__html: part.model}}></td>
                                        <td style={{width: '11%'}} dangerouslySetInnerHTML={{__html: part.remark}}></td>
                                        <td style={{width: '8%'}}>
                                          {part.tag?.sale_price}
                                        </td>
                                        <td style={{width: '8%'}}>
                                          {part.pid ? (salePrices[part.pid] ? (unit + salePrices[part.pid]) : <span style={{ color: '#ff0000' }}>{intl.formatMessage({ id: '待报价' })}</span>) : ''}
                                        </td>
                                        <td style={{width: '13%'}}>
                                          
                                          {
                                            part.pid ? (
                                              <div className={css.operation}>
                                                <Tooltip title={intl.formatMessage({ id: "详情" })}>
                                                  <span className="iconfont icon-xiangqing" style={{fontSize: 14}} onClick={()=>{tempPart.current = part; setTypeActive('basic')}}></span>
                                                </Tooltip>
                                                {
                                                  part.tag?.isreplace ? (
                                                    <Tooltip title={intl.formatMessage({ id: "替换件" })}>
                                                      <span className="iconfont icon-ti" onClick={()=>replace({pid: part.pid, brandCode: brandcode})}></span>
                                                    </Tooltip>
                                                  ): null
                                                }
                                                <Tooltip title={intl.formatMessage({ id: "购物车" })}>
                                                  <span className="iconfont icon-gouwuche1" onClick={()=>{tempPart.current = part; setTypeActive('goods')}}></span>
                                                </Tooltip>
                                              </div>
                                              
                                            ) : null
                                          }
                                        </td>
                                      </tr>
                                    ): null
                                  )
                                })
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                )
              }
              
            </div>
          </div>
        </div>
        {/* <div className={css['footer']}>
        声明:查询结果仅供参考
        </div> */}
      </div>
      <Modal
        title={intl.formatMessage({ id: "配置" })}
        open={configVisible}
        footer={null}
        onCancel={()=>setConfigVisible(false)}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder={intl.formatMessage({ id: "请输入配置信息" })} onChange={(e)=>setSearchConfigKey(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              Object.keys(config).map((key: string, index: number)=>{
                return (
                  !searchConfigKey || key.includes(searchConfigKey) || config[key].includes(searchConfigKey) ? (
                    <div className={css['item']} key={index}>
                      <label>{key}</label>
                      <span>{config[key]}</span>
                    </div>
                  ): null
                )
              })
            }
          </div>
        </div>
      </Modal>
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid}
            brandcode={brandcode}
            vin={vin}
          />
        ): null
      }
      {
        partDetailVisible ? (
          <Modal
            title={intl.formatMessage({ id: "零件详情" })}
            open={true}
            onCancel={()=>{setPartDetailVisible(false); setDetailPart({}); setTypeActive(''); setQuantity(0); tempPart.current=null}}
            width={1000}
            footer={null}
          >
            <PartDetail defaultPic={data.img ? data.img.imagePath : ''} detail={detailPart} pid={detailPart.pid} brandCode={brandcode} mcid={mcid} mid={mid} num={num} queryType={vin ? 'vin' : 'cars'} typeActive={typeActive} quantity={quantity} />
          </Modal>
          
        ) : null
      }
      <Modal
        open={rpVisible}
        onCancel={() => setRpVisible(false)}
        width={1000}
        footer={null}
      >
        <div className={styles['vehicle-brand-wrap']}>
          <div className={styles['vehicle-select']}>
            <span className={styles['vehicle-title']}>{intl.formatMessage({ id: "替换件" })}</span>
          </div>
          <div className={styles['vehicle-table-h']}>
            <table className={styles['table-header']}>
              <thead>
                <tr>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: "品牌" })}</th>
                  <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({ id: "OE号" })}</th>
                  <th style={{width: '20%', textAlign: 'center'}}>{intl.formatMessage({ id: "零件名称" })}</th>
                  <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({ id: "数量" })}</th>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: "详情" })}</th>
                  <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({ id: "价格" })}</th>
                </tr>
              </thead>
            </table>
            <Spin spinning={rpLoading}>
              <div className={styles.maxh}>
                {
                  replaceData.directly?.length ? (
                    <table>
                      <caption>{intl.formatMessage({ id: "直接替换" })}</caption>
                      <tbody>
                        {
                          replaceData.directly.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><Copy title={pid.pid} icon /></p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i:number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
                {
                  replaceData.indirect?.length ? (
                    <table>
                      <caption>{intl.formatMessage({ id: "间接替换" })}</caption>
                      <tbody>
                        {
                          replaceData.indirect.map((item: any, index: number)=>{
                            return (
                              <tr key={index}>
                                <td style={{width: '15%'}}>{item.brandcn}</td>
                                <td style={{width: '25%'}}>
                                  {
                                    item.pid.length > 1 ? (
                                      <div className={styles.flex}>
                                        <div>
                                          {item.parentnum} <span className="iconfont icon-dengyu"></span>
                                        </div>
                                        <div>
                                          {
                                            item.pid?.map((pid: any, i: number)=>{
                                              return (
                                                <p key={i}>{pid.pid}</p>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ): (
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}>{pid.pid}</p>
                                        )
                                      })
                                    )
                                  }
                                </td>
                                <td style={{width: '20%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.lable}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '10%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.num}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.remark}</p>
                                      )
                                    })
                                  }
                                </td>
                                <td style={{width: '15%'}}>
                                  {
                                    item.pid?.map((pid: any, i: number)=>{
                                      return (
                                        <p key={i}>{pid.cost_price}</p>
                                      )
                                    })
                                  }
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  ): null
                }
              </div>
            </Spin>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default VinDetail
