import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from './index.module.scss'
import logoPng from '../../assets/img/logo.png'
import avtorPng from '../../assets/img/avtor.png'
import { Button, Dropdown, message, notification } from 'antd'
import { DownOutlined, CaretDownOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd'
import { useLocation, useNavigate, useSearchParams  } from "react-router-dom";
import MainContext from '../../stores/stores'
import { useIntl } from 'react-intl'
import VinInput from "../../components/VinInput"
import orderApi from '../../api/orderApi'
import accountApi from "../../api/accountApi";
import lang_china from '../../assets/img/lang_china.png'
import lang_hk from '../../assets/img/lang_hk.png'
import lang_en from '../../assets/img/lang_en.png'
import lang_ru from '../../assets/img/lang_ru.png'
import lang_tr from '../../assets/img/lang_tr.png'
import { useAliveController } from 'react-activation'

import { useStateContext } from "@/YXUIKit/im-kit-ui/src";
import { observer } from 'mobx-react'


const Header: React.FC<any> = () => {
  const token = localStorage.getItem("token") || ''
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()
  const { refreshScope, clear, dropScope, drop, refresh  } = useAliveController()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { userInfo, pageType, logo, isWomala, host, colorPrimary, canLang, isCst, distributorInfo  } = commonStore
  const [longer, setLonger] = useState<boolean>(true)
  let domain = window.location.host
  
  const [standardNav, setStandardNav] = useState<any[]>([])
  const [moreNav, setMoreNav] = useState<any[]>([])
	
	

  useEffect(() => {
    getNavgation()
    if(token) {
      getCartList()
    }

    // if ("geolocation" in navigator) {
    //   navigator.geolocation.getCurrentPosition(
    //     function(position) {
    //       console.log("Latitude is :", position.coords.latitude);
    //       console.log("Longitude is :", position.coords.longitude);
    //     },
    //     function(error) {
    //       console.error("Error Code = " + error.code + " - " + error.message);
    //     }
    //   );
    //   navigator.geolocation.watchPosition(
    //     function(position) {
    //       console.log("Latitude is :", position.coords.latitude);
    //       console.log("Longitude is :", position.coords.longitude);
    //     },
    //     function(err) {
    //       console.log(22,err)
    //     }
    //   )
    // } else {
    //   console.error("Geolocation is not supported by this browser.");
    // }
  },[])

  useEffect(() => {
    const lang: any  = window.localStorage.getItem('lang')
    if(lang === 'zh' || lang === 'cht') {
      if(document.body.clientWidth > 1420) {
        setLonger(true)
      }else {
        setLonger(false)
      }
    }else {
      if(document.body.clientWidth > 1800) {
        setLonger(true)
      }else {
        setLonger(false)
      }
    }
    if((standardNav.length + moreNav.length) <= 6) {
      setLonger(true)
    }
  },[standardNav, moreNav])
	
	

  const getNavgation = () => {
    accountApi.getNavgationNew({ domain: host || domain }).then((res: any) => {
      if(res.code === 1) {
        let _standardNav = res.data.filter((item: any) => !item.is_add && item.is_check && item.path !== '/engine')
        let _moreNav = res.data.filter((item: any) => {
          if(item.is_add && item.is_check) {
            item.path = `/morepage/${item.code}`
            return true
          }
        })
        console.log("_moreNav:", _moreNav)
        setStandardNav(_standardNav)
        setMoreNav(_moreNav)
      }
    })
  }


  const showMore = useMemo<any>(() => {
    if(isWomala || moreNav.length) {
      return [
        {
          name: intl.formatMessage({ id: '更多' }),
          key: 'more'
        }
      ]
    }else {
      return []
    }
  },[moreNav])


  const getCartList = () => {
    orderApi.getCartList().then((res: any) => {
      if(res.code === 1) {
        let _data = res.data
        let length = 0
        Object.keys(_data).forEach((items: string) => {
          Object.keys(_data[items].goods_list).forEach((item: string) => {
            length += 1
          })
        })
        setCommonStore({
          type: 'cartNum',
          value: length 
        })
      }
    })
  }
  const goToUrl = (item: any) => {
    let is_must = item.is_must
    /* if(!is_must && item.is_add === 0 && ((commonStore.userInfo && commonStore.userInfo.verify_status && commonStore.userInfo.verify_status !== 1) || (commonStore.userInfo && !commonStore.userInfo.verify_status))) {
      message.warning(intl.formatMessage({ id: "暂无权限" }))
      return false
    } */
    /* if(item.path === '/enquery') {
      navigate("/login")
      return false
    } */
    let url = item.path
		
		if(url) {
			try {
				clear()
			}catch(err: any) {
				
			}
			setTimeout(() => {
        if((window.location.host.includes("partsbond") || window.location.host.includes("webtest")) && url === '/epc') {
          // if(window.localStorage.getItem("lang") === 'ru') {
          //   window.open('https://ru.partsbond.com',"__blank")
          // }else {
          //   window.open('https://partsbond.com',"__blank")
          // }
          if(window.location.host.includes("partsbond")) {
            if(window.localStorage.getItem("lang") === 'ru') {
              window.open('https://ru.partsbond.com',"__blank")
            }else {
              window.open('https://partsbond.com',"__blank")
            }
          }else {
            window.open("https://multilingual.xiaomaiqipei.com", "__blank")
          }
        }else {
          navigate(url)
        }
			},100)
			return false
		}
  }
  const toGo = (url: string) => {
    navigate(url)
  }

  const goMore = (item: any) => {
    navigate(item.path)
    // if(item.is_add) {
    //   navigate(`/morepage?key=${item.name}`)
    // }else {
    //   navigate(item.path)
    // }
  }

  const checkUrl = () => {
    if(token) {
      if(commonStore.userInfo && commonStore.userInfo.verify_status && commonStore.userInfo.verify_status === 1) {
        toGo('/mine/order')
      }else {
        toGo('/mine/setting')
      }
      
    }else {
      toGo('/login')
    }
  }
  const goToMain = () => {
    if(commonStore.userInfo && commonStore.userInfo.verify_status && commonStore.userInfo.verify_status === 1) {
      toGo('/mine/order')
    }else {
      toGo('/mine/setting')
    }
  }
	
	
	const openNew = () => {
		window.open("/merchant/order/list", "__blank")
	}
  const linkArr = [
    ...standardNav,
    ...showMore
  ]

  // 
  const moreArr: any = isWomala ? [
    {
      name: intl.formatMessage({ id: '关于我们' }),
      path: '/aboutus',
      code: 'about_us'
    },
    {
      name: intl.formatMessage({ id: '联系我们' }),
      path: '/contactus',
      code: 'contract_us'
    },
    {
      name: intl.formatMessage({ id: '疑问解答' }),
      path: '/doubt',
      code: 'faq'
    },
    {
      name: intl.formatMessage({ id: '购买流程' }),
      path: '/buypath',
      code: 'purchase_process'
    },
  ]: moreNav


  const setLang = (lang: "zh" | "en" | "ru" | "cht" | "tr") => {
    setSearchParams({})
    window.localStorage.setItem('lang', lang)
    window.location.href = "/"
  }
  const langItems: any = [
    {
      key: 'zh',
      label: (
        <div className="flex-s-c" onClick={() => setLang('zh')}><img className="mr5" src={lang_china} style={{ height: 20 }} />中文</div>
      ),
      logo: lang_china
    },
    {
      key: 'en',
      label: (
        <div className="flex-s-c" onClick={() => setLang('en')}><img className="mr5" src={lang_en} style={{ height: 20 }} />English</div>
      ),
      logo: lang_en
    },
    {
      key: 'ru',
      label: (
        <div className="flex-s-c" onClick={() => setLang('ru')}><img className="mr5" src={lang_ru} style={{ height: 20 }} />Русский язык</div>
      ),
      logo: lang_ru
    },
    {
      key: 'cht',
      label: (
        <div className="flex-s-c" onClick={() => setLang('cht')}><img className="mr5" src={lang_hk} style={{ height: 20 }} />中文-繁體</div>
      ),
      logo: lang_hk
    },
    {
      key: 'tr',
      label: (
        <div className="flex-s-c" onClick={() => setLang('tr')}><img className="mr5" src={lang_tr} style={{ height: 20 }} />Türkiye</div>
      ),
      logo: lang_tr
    },
  ].filter((item: any) => canLang && canLang.includes(item.key))
  const lang: any  = window.localStorage.getItem('lang')
  console.log("lang1111:", lang)
  
  
  const _linkArr = longer ? [
    ...standardNav,
    ...moreArr
  ] : linkArr
	console.log("_linkArr:", _linkArr, "longer:", longer)
  return (
    <div className={styles['header']} style={{ height: pageType === '1' ? 150 : pageType === '2' ? 70 : 150 }}>
      {
        pageType === '1' || pageType === '3' ? (
          <>
            <div className={styles['header-t']}>
              {
                token && commonStore.imChatData.appKey ? <LogoImg/> : <img src={logo || ""} alt="" />
              }
              <div className={styles['tr']}>
                <div className="flex-s-c" style={{ flexDirection: 'row-reverse' }}>
                  {
                    token ? (
                      <div className="flex-s-c pointer"  onClick={() => toGo('/cart')}>
                        <i className="iconfont icon-a-gouwucheman2 mr5"/>
                        {intl.formatMessage({ id: '购物车' })}({commonStore.cartNum})
                      </div>
                    ): null
                  }
                  <div className="flex-s-c mr30 pointer"  onClick={() => checkUrl() }>
                    <i className="iconfont icon-a-wo1 mr5"/>
                    {
                      token ? (
                        <>
                          {userInfo.wechat_nick || userInfo.phone}
                        </>
                      ): <>{intl.formatMessage({ id: '请登录' })}</>
                    }
                    <i className="iconfont icon-down ml5" />
                  </div>
                  <div className="flex-s-c" >
                    <Dropdown menu={{ items: langItems }}>
                      <div className={styles['lang'] + ' flex-s-c mr30 pointer'} style={{ color: '#333333' }}>
                        <img style={{ height: 20, marginRight: 6 }} src={langItems.filter((item: any) => item.key === lang)[0]?.logo} />
                        { lang === 'zh' ? '中文' : lang === 'en' ? 'English' : lang === 'ru' ? 'Русский язык' : lang === 'cht' ? '中文-繁體' : lang === 'tr' ? 'Türkiye' : '中文'  }
                      </div>
                    </Dropdown>
                  </div>
									{
										userInfo.is_repair_shop_admin && distributorInfo.status ? (
											<div className='flex-s-c'>
												<div onClick={() => navigate("/mine/invitation")} className={styles['invitation-code'] + ' flex-s-c mr20 pointer'}>
													<img className="mr5" style={{ width: 14 }} src="https://userimgs.007cst.com/20240716/ce96dba4187ca42b1b2cf076ba4eeaa4.png" />
													{intl.formatMessage({ id: '邀请码' })}
												</div>
												<div className={'pointer flex-s-c mr20'} onClick={() => openNew()}>
													<i className="iconfont icon-a-Group21471 mr10"/>
													{intl.formatMessage({ id: '商户后台' })}
												</div>
											</div>
										): null
									}
                </div>
								
                {/* <div className="mt15">
                  <VinInput
                    cameraButtonStyle={{ color: '#061357' }}
                    enterButtonStyle={{ backgroundColor: isWomala ? '#D80000' : colorPrimary }}
                  />
                </div> */}
              </div>
            </div>
            <div className={styles['header-b']} style={{ backgroundColor: colorPrimary }}>
              <div className={styles['links']}>
                {
                  _linkArr.map((link: any, index: any) => {
                    if(link.key) {
                      console.log('moreArr:', moreArr, pathname)
                      let nowItem = moreArr.filter((item: any) => item.path === pathname)[0]
                      
                      return (
                        <Dropdown menu={{ 
                          items: moreArr.map((item: any) => {
                            return {
                              key: item.path,
                              label: <div onClick={() => goMore(item)}>{item.name}</div>
                            }
                          }) 
                        }}
                        >
                          <div className={styles['link'] + ' ' + `${ nowItem ? styles['active'] : '' }`} key={index} >
                            {nowItem ? nowItem.name : link.name}
                            <CaretDownOutlined className="ml5" />
                          </div>
                        </Dropdown>
                      )
                    }
                    return (
                      <div className={styles['link'] + " " + `${pathname.indexOf(link.path) === 0 ? styles['active'] : ''}`} key={index} onClick={() => goToUrl(link)}>
                        {link.is_must ? <i className={`iconfont mr5 icon-a-shouye2`}/> : null}{link.name}
                      </div>
                    )
                  })
                }
              </div>
              {/* <div className="flex-s-c" >
                <Dropdown menu={{ items: langItems }}>
                  <div className={styles['lang'] + ' flex-s-c mr30 pointer'}>
                    <img style={{ height: 20, marginRight: 6 }} src={langItems.filter((item: any) => item.key === lang)[0]?.logo} />
                    { lang === 'zh' ? '中文' : lang === 'en' ? 'English' : lang === 'ru' ? 'Русский язык' : lang === 'cht' ? '中文-繁體' : '中文'  }
                  </div>
                </Dropdown>
              </div> */}
            </div>
          </>
        ): (
          <div  className={styles['type2']}  style={{ backgroundColor: colorPrimary }}>
            <div className={styles['left']}>
              {
                token && commonStore.imChatData.appKey ? <LogoImg/> : <img className={styles['logo']} src={logo || ""} alt="" />
              }
              {
                _linkArr.map((link: any, index: number) => {
                  if(link.key) {
                    let nowItem = moreArr.filter((item: any) => item.path === pathname)[0]
                    return (
                      <Dropdown menu={{ 
                        items: moreArr.map((item: any) => {
                          return {
                            key: item.path,
                            label: <div onClick={() => goMore(item)}>{item.name}</div>
                          }
                        }) 
                      }}
                      >
                        <div className={styles['link'] + ' ' + `${ nowItem ? styles['active'] : '' }`} key={index} >
                          {nowItem ? nowItem.name : link.name}
                          <CaretDownOutlined className="ml5" />
                        </div>
                      </Dropdown>
                    )
                  }
                  if(!link.path) return null
                  return (
                    <div key={index} className={styles['link'] + " " + `${pathname.indexOf(link.path) === 0 ? styles['active'] : ''}`} onClick={() => goToUrl(link)}>{link.name}</div>
                  )
                })
              }
              
            </div>
            <div className={styles['right']}>
							{
								userInfo.is_repair_shop_admin && distributorInfo.status ? (
									<div className='flex-s-c'>
										<div onClick={() => navigate("/mine/invitation")} className={styles['invitation-code'] + ' flex-s-c mr20 pointer'}>
											<img className="mr5" style={{ width: 14 }} src="https://userimgs.007cst.com/20240716/ce96dba4187ca42b1b2cf076ba4eeaa4.png" />
											{intl.formatMessage({ id: '邀请码' })}
										</div>
										<div className={'pointer flex-s-c mr20'} onClick={() => openNew()}>
											<i className="iconfont icon-a-Group21471 mr10"/>
											{intl.formatMessage({ id: '商户后台' })}
										</div>
									</div>
								): null
							}
							
              <Dropdown menu={{ items: langItems }}>
                <div className={styles['lang'] + ' flex-s-c mr20 pointer'}>
                  {/* <i className="iconfont icon-a-yuyan11 mr5 " /> */}
                  <img style={{ height: 20, marginRight: 6 }} src={langItems.filter((item: any) => item.key === lang)[0]?.logo} />
                  { lang === 'zh' ? '中文' : lang === 'en' ? 'English' : lang === 'ru' ? 'Русский язык' : lang === 'cht' ? '中文-繁體' : lang === 'tr' ? 'Türkiye' : '中文'  }
                </div>
              </Dropdown>
              {
                token ? (
                  <div className="flex-s-c  pointer"  onClick={() => goToMain()}>
                    <i  className="iconfont icon-a-wo1"/>
                    <span className="mlr5">{userInfo.wechat_nick || userInfo.phone}</span>
                    <i className="iconfont icon-down" />
                  </div>
                ): (
                  <>
                    <Button onClick={() => navigate('/login')} className={styles['nologin-btn'] + " mr10"} type="primary" ghost>{intl.formatMessage({ id: '登录' })}</Button>
                    <Button onClick={() => navigate('/register')} className={styles['nologin-btn']} type="primary" ghost>{intl.formatMessage({ id: '注册' })}</Button>
                  </>
                )
              }
            </div>
          </div>
        )
      }
    </div>
  )
}


const LogoImg: React.FC = observer(() => {
  const { pathname } = useLocation()
  const intl = useIntl()
  const navigate = useNavigate()
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { logo } = commonStore
  const { store, nim } = useStateContext();
  const unreadMsgsCount = store.uiStore.sessionUnread || 0

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    const onMsg = (msg: any) => {
      console.log("sider msg:", msg)
      let sessionId = msg.sessionId
      let idClient = msg.idClient
      let custom = msg.custom
      let fromClientType = msg.fromClientType
      let type = msg.type
      let body = msg.body
      if(type === 'text') {
        let msgTextObj = msg.body && JSON.parse(msg.body) ? JSON.parse(msg.body) : {}
        let isFromOrder = msgTextObj.notice_type && (msgTextObj.notice_type === 'refund' || msgTextObj.notice_type === 'order_quote_finished' || msgTextObj.notice_type === 'order_wait_send' || msgTextObj.notice_type === 'user_verify') ? true : false
        if(isFromOrder) {
          store.sessionStore.deleteSessionActive(sessionId)
          setCommonStore({
            type: 'msgNoticeData',
            value: msg
          })
          openNotification(msgTextObj)
        }
      }
    }
    nim.on('msg', onMsg)

    return () => {
      nim.off('msg', onMsg)
    }
  }, [nim, unreadMsgsCount])
  
  useEffect(() => {
    if(nim && store.connectStore) {
      console.log("store.connectStore.connectState:", store.connectStore.connectState)
      if(store.connectStore.connectState && store.connectStore.connectState === 'connected') {
        setCommonStore({type:'isOperate',value:true})
      }
      if(store.connectStore.connectState && store.connectStore.connectState === 'disconnected') {
        setCommonStore({type:'isOperate',value:false})
      }
    }
  }, [store.connectStore, nim])

  const openNotification = (data: any) => {
		if(data.notice_type === 'refund') {
		  const key = `open${Date.now()}`;
		  const btn = (
		    <Button 
					type="primary" 
					onClick={() => {
						api.destroy();
						if(pathname === '/mine/aftersales/detail') {
							window.location.reload()
						}else {
							navigate(`/mine/aftersales/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.inquiry_id}&refund_id=${data.refund_id}`)
						}
					}}>
					{intl.formatMessage({ id: '查看详情' })}
				</Button>
		  );
		  let refund_status = data.refund_status
		  let message;
		  if(refund_status === 1) {
		    message = intl.formatMessage({ id: '售后订单商家已同意！' })
		  }else {
		    message = intl.formatMessage({ id: '售后订单商家已拒绝！' })
		  }
		  api.open({
		    message: message,
		    duration: null,
		    description:(
		      <div>
		        <div className="mt10">
							{intl.formatMessage({ id: '创建时间' })}:{data.create_time}
						</div>
						<div className="mt10">
              {intl.formatMessage({ id: '处理时间' })}:{data.send_time}
						</div>
		      </div>
		    ),
		    btn,
		    key,
		    // onClose: close,
		  });
		}
    if(data.notice_type === 'order_quote_finished' || data.notice_type === 'order_wait_send') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={
					() => {
						if(window.location.pathname === '/mine/order/detail') {
						  window.location.reload()
						}else {
						  api.destroy(); 
							navigate(`/mine/order/detail?merchant_id=${data.merchant_id}&inquiry_id=${data.enquiry_info.enquiry_id}`)
						}
					}
				}
				>{intl.formatMessage({ id: '查看详情' })}</Button>
      );
      let inquiry_status = data.inquiry_status
      let car_info = data.car_info 
      let message;
      if(inquiry_status === 5) {
        message = intl.formatMessage({ id: '订单已报价，请尽快处理！' })
      }else {
        message = intl.formatMessage({ id: '订单已发货，请注意查收！' })
      }
      api.open({
        message: message,
        duration: null,
        description:(
          <div>
            {
              car_info ? (
                <div className="flex-s-c">
                  {car_info.brand_logo ? <img style={{ height: 20, border: '1px solid #f6f6f6' }} src={car_info.brand_logo} /> : ''}
                  {car_info.market_name}
                </div>
              ): null
            }
            <div className="mt20">{intl.formatMessage({ id: '创建时间' })}：{data.order_create_time}</div>
            {
              inquiry_status === 5 ? (
                <div className="mt10">{intl.formatMessage({ id: '报价时间' })}：{data.quote_time}</div>
              ): inquiry_status === 8 ? (
                <div className="mt10">{intl.formatMessage({ id: '发货时间' })}：{data.send_time}</div>
              ): null
            }
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
    if(data.notice_type === 'user_verify') {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" onClick={() => {
          if(window.location.pathname === '/mine/company') {
            window.location.reload()
          }else {
            api.destroy(); navigate(`/mine/company`)
          }
        }
        }>{intl.formatMessage({ id: '前往处理' })}</Button>
      );
      let phone = data.phone
      let _phone = phone.slice(-4)
      let message = `尾号${_phone}用户，提交了认证审核！`
      let time = data.create_time
      api.open({
        message: intl.formatMessage({ id: '审核单' }),
        duration: null,
        description:(
          <div>
            <div style={{color: '#333333', fontSize: 14}}>{message}</div>
            <div style={{marginTop: 8, color: '#888888', fontSize: 14}}>{time}</div>
          </div>
        ),
        btn,
        key,
        // onClose: close,
      });
    }
  };
  return (
    <>
      {contextHolder}
      <img className={styles['logo']} src={logo || ""} alt="" />
    </>
    
  )
})

export default Header