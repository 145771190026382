import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import MainContext from '@/stores/stores'
import { message, Modal, Spin } from "antd"
import { PartSearch, SearchPartInput, Breadcrumb } from '@/pages/Epc'
import vinApi from '@/api/epcApi'
import { getUrlParams, cdnsPath } from '@/utils/utils'
import css from './vin.module.scss'
import { useIntl } from 'react-intl'
import orderApi from '../../api/orderApi'
import accountApi from '../../api/accountApi';
import { Supplement } from '../Mine/Company'

const Vin: React.FC = () => {
  const urlParams = JSON.stringify(getUrlParams())
  const { commonStore, setCommonStore } = useContext(MainContext)
  const intl = useIntl()
  const { crumb, vinData, pageType } = commonStore
  const navigate = useNavigate()
  const { vin, num, index, mcid, brandcode, samecrumb, vinmcid, name, id } = getUrlParams()
  const [ data, setData ] = useState<any>({})
  const [ group, setGroup ] = useState<any>([])
  const [ structure, setStructure ] = useState<any>([])
  const [ subGroup, setSubGroup ] = useState<any>([])
  const [ title, setTitle ] = useState<string[]>([])
  const [ key, setKey ] = useState<string>('')
  const [ groupKey, setGroupKey ] = useState<string>('')

  const [ groupActive, setGroupActive ] = useState<number>(-1)
  const [ structureActive, setStructureActive ] = useState<number>(-1)
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ structureShow, setStructureShow ] = useState<boolean>(localStorage.getItem('structureShow') ? true : false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ partKey, setPartKey ] = useState<string>('')
  const [ groupTotal, setGroupTotal ] = useState<any>({structure: 0, sub: 0})
  const [ subGroupShow, setSubGroupShow ] = useState<any>({menu: 'main', content: false})
  
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ config, setConfig ] = useState<any>([])

  const [ selectConfig, setSelectConfig ] = useState<any>({data: {}})
  const [ selectConfigVisible, setSelectConfigVisible ] = useState<boolean>(false)

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<number>(0)
  const [ progressVisible, setProgressVisible ] = useState<boolean>(false)
  const [supplementOpen, setSupplementOpen] = useState<boolean>(false)
  const [companyInfo, setCompanyInfo] = useState<any>({})
  
  let activeIndex = 0

  useEffect(()=>{
    if(vin && id){
      const param = {
        vin,
        id,
        name,
        pop: true
      }
      setCommonStore({value: param, type: 'param'})
      window.sessionStorage.setItem('param', JSON.stringify(param))
      window.sessionStorage.setItem('searchKey', name || '')
    }
    if((vinData.vin && vinData.vin === vin || vinData.mcid && vinData.mcid === mcid)){
      setData(vinData.data || {})
      setGroup(vinData.group || [])
      setStructure(vinData.structure || [])
      setSubGroup(vinData.subGroup || [])
      setGroupTotal(vinData.groupTotal || {structure: 0, sub: 0})
      return
    }
    if(vin){
      getVin(true, urlParams, vin, brandcode)
    }
    
    // catalog
    if(mcid && brandcode){
      getGroup({
        brandCode: brandcode,
        mcid: mcid
      })
    }
  }, [])

  useEffect(()=>{
    if(configVisible){
      searchConfig()
    }
  }, [configVisible])

  useEffect(()=>{
    if(num && group.length){
      group.forEach((item: any, index: number)=>{
        if((num === '0' && !index) || item.num == num || item.tempNum == num){
          if(!samecrumb){
            let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
            item.name = item.label
            item.type = 'group'
            tempCrumb.push(item)
            setCommonStore({value: tempCrumb, type: 'crumb'})
          }
          getStructure(index, true)
        }
      })
    }
  }, [group])

  useEffect(()=>{
    if(index && structure.length){
      getSubGroup(parseFloat(index), true)
    }
  }, [structure])

  useEffect(()=>{
    if(progress && vin){
      getVin(false, urlParams, vin)
    }
  }, [progress])


  useEffect(() => {
    if(commonStore.userInfo && commonStore.userInfo.repair_shop_id) {
      getCompanyInfo()
    }
  }, [commonStore.userInfo])

  
  const getCompanyInfo = () => {
    orderApi.getCompanyInfo({
      repair_shop_id: commonStore.userInfo && commonStore.userInfo.repair_shop_id ? commonStore.userInfo.repair_shop_id : '',
    }).then((res: any) => {
      if(res.code === 1) {
        setCompanyInfo(res.data)
      }
    })
  }
  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        setCommonStore({value: res.data, type: 'userInfo'})
      }
    })
  }

  const getVin = (loading: boolean, urlParamsOld: string, pVin: string, brandcode?: string, mcid?: string) => {
    const urlParamsTemp = JSON.stringify(getUrlParams()) || null
    if(urlParamsOld !== urlParamsTemp){
      return
    }
    let param: any = {
      vin: pVin
    }
    if(brandcode){
      param.brandCode = brandcode
    }
    if(mcid){
      param.mcid = mcid
    }
    setSelectConfig({data: {}})
    setSelectConfigVisible(false)
    if(loading){
      setLoading(true)
    }
    
    vinApi.vin(param).then((result: any)=>{
      const urlParamsTemp = JSON.stringify(getUrlParams()) || null
      if(urlParamsOld !== urlParamsTemp){
        return
      }
      if(result.code === 1001) {
        setSupplementOpen(true)
      }
      if(result.code === 1004) {
        Modal.info({
          title: intl.formatMessage({ id: '正在等待所在公司的审核！' }),
          content: intl.formatMessage({ id: '审核通过后可获得更多商品查询次数' }),
          okText: intl.formatMessage({ id: '知道了' }),
          onOk: () => {

          }
        })
      }
      if(result.code === 1 || result.code === 10) {
        let vinTemp = result.data.vin || vin
        let tempCrumb: any = [
          {
            type: 'logo',
            img: `${cdnsPath}/img/${result.brandCode}.png`
          },
          {
            name: vinTemp.length > 20 ? vin : vinTemp
          }
        ]
        const keyword = [
          vinTemp,
          `${result.data.name}-${result.data.year}`
        ]
        
        const hp = {
          query_type: 'vin',
          keyword: keyword.join('\n'),
          brandCode: result.brandCode
        }
        vinApi.setHistory(hp).then()
        result.title?.map((name: string)=>{
          tempCrumb.push({
            name,
            type: 'config'
          })
        })
        if (result.data && result.data.maindata) {
          result.data.config_list = []
          if (!result.data.mainkeys) {
            result.data.config_list = Object.keys(data.data.maindata);
          }
          result.data.mainkeys.forEach((item:any) => {
            result.data.config_list.push({
              title: item,
              value: result.data.maindata[item],
            })
          })
        }
        window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
        if(!samecrumb){
          setCommonStore({value: tempCrumb, type: 'crumb'})
        }
        let vinDataTemp = {
          ...param,
          data: result
        }

        if(vinTemp.length > 20 || vin.length < 10){
          navigate(`/epc/vin?vin=${vinTemp}`)
        }
        
        if(name){
          setPartKey(name)
          setPartSearchkey(1)
          navigate(`/epc/vin?vin=${vinTemp}`)
        }

        setData(result)
        getGroup({
          vin: vinTemp,
          brandCode: result.brandCode,
          mcid: result.data.mcid
        }, vinDataTemp)
        if(!vinmcid){
          const url = window.location.search + `&vinmcid=${result.data.mcid}&vincode=${result.brandCode}`
          navigate(url)
        }
      }else if(result.code !== 4001) {
        setLoading(false)
      }
      if(result.code === 4005) {
        let list: any = []
        let columns: any = []
        if(result.select_data){
          result.title.forEach((item: any)=>{
            columns.push({
              title: item,
              dataIndex: item
            })
          })
          result.select_data.forEach((item: any, index: number)=>{
            let obj: any = {}
            item.contentData.forEach((content: any, i: number)=>{
              obj[result.title[i]] = content
            })
            obj.mcid = item.mcid
            obj.title = result.title
            obj.key = index
            if(item.vehicleid){
              obj.vehicleid = item.vehicleid
            }
            list.push(obj)
          })
        }else {
          result.data.mcid.title.forEach((item: any)=>{
            columns.push({
              title: item,
              dataIndex: item
            })
          })
          result.data.mcid.data.forEach((item: any, index: number)=>{
            let obj: any = {}
            item.contentData.forEach((content: any, i: number)=>{
              obj[result.data.mcid.title[i]] = content
            })
            obj.mcid = item.mcid
            obj.vin = result.data.vin
            obj.brandCode = result.data.brandCode
            obj.title = result.title
            obj.key = index
            list.push(obj)
          })
        }

        result.columns = columns
        result.list = list

        let sc = {
          ...param,
          data: result
        }
        setSelectConfig(sc)
        setSelectConfigVisible(true)
      }
      if(result.code === 4003){
        let columns = [
          {
            title: intl.formatMessage({ id: "图片" }),
            dataIndex: 'full_imglogo',
            type: 'img'
          },
          {
            title: 'vin',
            dataIndex: 'vehicleid'
          },
          {
            title: intl.formatMessage({ id: "名称" }),
            dataIndex: 'name'
          },
          {
            title: intl.formatMessage({ id: "年份" }),
            dataIndex: 'year'
          }
        ]
        
        result.data.forEach((item: any)=>{
          item.vehicleid = item.vin
          delete item.vin
        })
        result.columns = columns
        result.list = result.data
        let sc = {
          ...param,
          data: result
        }
        setSelectConfig(sc)
        setSelectConfigVisible(true)
      }
      if(result.code === 4001) {
        if(progress){
          if(progress < 3){
            setTimeout(()=>{
              setProgress(progress + 1)
            }, 3000)
          }else {
            // setProgressVisible(false)
            setLoading(false)
            setProgress(0)
            message.warning(result.msg)
          }
        }else {
          // setProgressVisible(true)
          setTimeout(()=>{
            setProgress(1)
          }, 3000)
        }
      }
    })
  }
  const getGroup = (param: object, vd?: {}) => {
    vinApi.group(param).then((result: {code: number, data: any})=>{
      setLoading(false)
      if(result.code === 1) {
        result.data.forEach((item: any)=>{
          if(item.group_type === 'maintenance'){
            item.tempNum = '8888'
          }
        })
        if(result.data[num || 0]?.click === 0){
          result.data.forEach((item: any)=>{
            if(item.click === 1){
              const url = window.location.search.replace(/&num=\d/, '&num=' + item.num)
              navigate(url)
            }
          })
        }
        let vinDataTemp = vd || JSON.parse(JSON.stringify(vinData))
        vinDataTemp.group = result.data
        
        setCommonStore({value: vinDataTemp, type: 'vinData'})
        setGroup(result.data)
      }
    })
  }
  const getStructure = (index: number, jump?: boolean) => {
    if(groupActive === index){
      return
    }
    if(group[index].click === 0){
      setCommonStore({value: true, type: 'purchaseVisible'})
      return
    }
    activeIndex = index
    setGroupActive(index)
    setSubGroupShow({
      menu: 'main',
      content: false
    })
    setStructureActive(-1)
    if(jump && vinData.vin && vinData.structure){
      return
    }

    const item = group[index]
    const param: any = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num
    }
    let api: any = {}
    if(item.group_type){
      param.group_type = item.group_type
      api = vinApi.subGroup(param)
    }else {
      param.num = item.num
      api = vinApi.structure(param)
    }
    setStructure([])
    api.then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === index) {
        let total = 0
        result.data.forEach((d: any)=>{
          if(item.group_type){
            d.img = d.img_url
            d.mcid = item.mcid
            d.brandCode = item.brandCode
            d.num = item.tempNum
          }else {
            // d.img = d.structure_illustration ? (result.imgs[d.structure_illustration] || result.imgs[d.imageLarge] || '') : (result.imgs[d.imageLarge] || '')
            d.img = result.imgs[d.structure_illustration] || result.imgs[d.imageLarge] || ''
          }
          
          
          // catalog
          if((mcid && brandcode) || item.group_type){
            d.colorvalue = 1
          }

          if(d.colorvalue){
            total++
          }
        })
        groupTotal.structure = total

        let vinDataTemp = JSON.parse(JSON.stringify(vinData))
        vinDataTemp.structure = result.data
        vinDataTemp.groupTotal = groupTotal
        setCommonStore({value: vinDataTemp, type: 'vinData'})

        setGroupTotal(groupTotal)
        setStructure(result.data)
      }
    })
    if(!jump){
      let url = `/epc/vin?vin=${vin}&num=${item.num || item.tempNum}&brandcode=${brandcode}`
      //catalog
      if(mcid && brandcode){
        url = `/epc/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}`
      }
      
      item.name = item.label
      item.type = 'group'
      let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
      tempCrumb.push(item)
      setCommonStore({value: tempCrumb, type: 'crumb'})
      
      navigate(url)
    }else if(samecrumb){
      const url = window.location.search.replace('&samecrumb=true', '')
      navigate(url)
    }
  }
  const getSubGroup = (index: number, jump?: boolean) => {
    if(structureActive === index){
      return
    }
    if(structure[index].click === 0){
      setCommonStore({value: true, type: 'purchaseVisible'})
      return
    }
    activeIndex = index
    setStructureActive(index)
    setSubGroupShow({
      menu: 'structure',
      content: true
    })
    if(jump && vinData.vin && vinData.structure){
      return
    }
    const item = structure[index]
    const param = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num,
      subgroup: item.subgroup,
      maingroup: item.maingroup,
      level: 1
    }
    setSubGroup([])
    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === index) {
        let total = 0
        result.data.forEach((d: any)=>{
          d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
          // catalog
          if(mcid && brandcode){
            d.colorvalue = 1
          }
          if(d.colorvalue){
            total++
          }
        })
        groupTotal.sub = total
        setGroupTotal(groupTotal)
        setSubGroup(result.data)

        let vinDataTemp = JSON.parse(JSON.stringify(vinData))
        vinDataTemp.subGroup = result.data
        vinDataTemp.groupTotal = groupTotal
        setCommonStore({value: vinDataTemp, type: 'vinData'})
      }
    })
    let url = `/epc/vin?vin=${vin}&num=${group[groupActive].num}&index=${index}`
    //catalog
    if(mcid && brandcode){
      url = `/epc/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&index=${index}`
    }
    if(crumb[crumb.length - 1].level === 1){
      crumb.pop()
    }
    
    item.type = 'subGroup'
    crumb.push(item)
    setCommonStore({value: crumb, type: 'crumb'})
    
    navigate(url)
  }
  const goDetail = (index: number, subIndex?: number) => {
    const item = structure[index]
    if(item.click === 0){
      setCommonStore({value: true, type: 'purchaseVisible'})
      return
    }
    let param = `vin=${vin || ''}&brandcode=${item.brandCode}&mcid=${item.mcid}&num=${item.num || item.tempNum}&index=${index}`
    sessionStorage.setItem('structure', JSON.stringify(structure))
    
    if(subIndex || subIndex === 0){
      const subItem = subGroup[subIndex]
      param = `vin=${vin || ''}&brandcode=${subItem.brandCode}&mcid=${subItem.mcid}&num=${subItem.num}&index=${index}&subindex=${subIndex}`
      sessionStorage.setItem('subGroup', JSON.stringify(subGroup))
      subItem.type = 'detail'
      subItem.index = index
      crumb.push(subItem)
      setCommonStore({value: crumb, type: 'crumb'})
    }else {
      item.type = 'detail'
      crumb.push(item)
      setCommonStore({value: crumb, type: 'crumb'})
      sessionStorage.removeItem('subGroup')
    }
    if(item.final_id){
      param += `&finalid=${item.final_id}`
    }
    let url = `/epc/vin/detail?${param}`
    //catalog
    if(mcid && brandcode){
      url = `/epc/group/detail?${param}`
    }

    let tempCrumb = window.sessionStorage.getItem('crumb') || '[]'
    window.sessionStorage.setItem('groupCrumb', tempCrumb)
    window.sessionStorage.setItem('crumb', JSON.stringify(crumb))

    navigate(url)
  }
  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
    }
  }
  const setStructureShowLS = () => {
    let val = structureShow ? false : true
    setStructureShow(val)
    if(val){
      localStorage.setItem('structureShow', '1')
    }else {
      localStorage.removeItem('structureShow')
    }
  }
  const setSubGroupShowFn = (type: string) => {
    let temp = JSON.parse(JSON.stringify(subGroupShow))
    temp.menu = type
    setSubGroupShow(temp)
  }

  const searchConfig = (key_words?: string) => {
    if(key_words){
      vinApi.config({vin, brandCode: brandcode, key_words}).then((result: {code: number, data: any})=>{
        if(result.code === 1){
          setConfig(result.data.confs ? result.data.confs : [])
        }else {
          setConfig([])
        }
      })
    }else {
      setConfig([])
    }
  }

  const back = () => {
    if(!index){
      setGroupActive(-1)
      
      crumb.splice(crumb.length - 1, 1)
      setCommonStore({value: crumb, type: 'crumb'})
    }else {
      setSubGroupShow({
        menu: 'main',
        content: false
      })
      let url = `/epc/vin?vin=${vin}&num=${num}`
      navigate(url)
    }
    
  }
  const clickConfig = (item: any) => {
    if(!item.vin){
      getVin(true, urlParams, item.vehicleid || vin, selectConfig.data.brandCode, item.mcid)
    }else {
      const result = selectConfig.data
      setSelectConfigVisible(false)

      let tempCrumb: any = [
        {
          type: 'logo',
          img: `${cdnsPath}/img/${result.brandCode}.png`
        },
        {
          name: vin
        }
      ]
      
      result.title?.map((name: string)=>{
        tempCrumb.push({
          name,
          type: 'config'
        })
      })
      if (result.data && result.data.maindata) {
        result.data.config_list = []
        if (!result.data.mainkeys) {
          result.data.config_list = Object.keys(data.data.maindata);
        }
        result.data.mainkeys.forEach((item:any) => {
          result.data.config_list.push({
            title: item,
            value: result.data.maindata[item],
          })
        })
      }
      window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      if(!samecrumb){
        setCommonStore({value: tempCrumb, type: 'crumb'})
      }
      let vinDataTemp = JSON.parse(JSON.stringify(selectConfig))

      if(name){
        setPartKey(name)
        setPartSearchkey(1)
        navigate(`/epc/vin?vin=${vin}`)
      }

      setData(result)
      getGroup({
        vin,
        brandCode: result.brandCode,
        mcid: item.mcid
      }, vinDataTemp)
      if(!vinmcid){
        const url = window.location.search + `&vinmcid=${item.mcid}&vincode=${result.brandCode}`
        navigate(url)
      }
    }
  }
  return (
    <>
      <Breadcrumb />
      {
        vin || (mcid && brandcode) ? (
          <Spin spinning={loading} indicator={<img src="https://cdns.007vin.com/img/autorepair/cst/car_loading.gif"/>} wrapperClassName={css['list-spin']}>
            <div className={css['vin']}>
              <div className={css['l']}>
                <div className={css['box']}>
                  <div className={css['box-top']} onClick={()=>setSubGroupShowFn('main')}>
                    <h2>{intl.formatMessage({ id: "主组" })}({group.length})</h2>
                    <div className={css['box-top-tools']}>
                      <span>
                        {/* <input value={key} type="text" placeholder="Maingroup Search" onChange={(e)=>setKey(e.target.value)} /> */}
                      </span>
                    </div>
                  </div>
                  {
                    subGroupShow.menu === 'main' ? (
                      <div className={subGroupShow.content ? css['box-content-h'] : css['box-content']}
                        style={{ height: subGroupShow.content ? '' : `calc(100vh - ${pageType === '2' ? '162' : '242'}px)` }}
                      >
                        <ul className={css['group']}>
                          {
                            group.map((item: {label: string, img: string, num: string, click?: number}, index: number) => {
                              return (
                                !key || item.label.indexOf(key) !== -1 || item.num.indexOf(key) !== -1 ? (
                                  <li key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                    <img src={item.img} />
                                    <p>{item.num} {item.label}</p>
                                    {
                                      item.click === 0 ? <div></div> : null
                                    }
                                  </li>
                                  // <li key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                  //   <span>{item.num}</span>
                                  //   <p>{item.label}</p>
                                  //   <img src={item.img} />
                                  //   {
                                  //     item.click === 0 ? <div></div> : null
                                  //   }
                                  // </li>
                                ): null
                              )
                            })
                          }
                        </ul>
                      </div>
                    ): null
                  }
                  {
                    subGroupShow.content ? (
                      <>
                        <div className={css['box-top']} onClick={()=>setSubGroupShowFn('structure')}>
                          <h2>{intl.formatMessage({ id: '组' })}({structure.length})</h2>
                          <div className={css['box-top-tools']}>
                            <span>
                              {/* <input value={groupKey} type="text" placeholder="Group Search" onChange={(e)=>setGroupKey(e.target.value)} /> */}
                              {/* <i className="iconfont iconsousuo" /> */}
                            </span>
                          </div>
                        </div>
                        {
                          subGroupShow.menu === 'structure' ? (
                            <div className={subGroupShow.content ? css['box-content-h'] : css['box-content']}
                              style={{ height: subGroupShow.content ? '' : `calc(100vh - ${pageType === '2' ? '162' : '242'}px)` }}
                            >
                              <ul className={css['group']}>
                                {
                                  structure.map((item: {name: string, img: string, mid: string, click?: number}, index: number) => {
                                    return (
                                      !groupKey || item.name.indexOf(groupKey) !== -1 || item.mid.indexOf(groupKey) !== -1 ? (
                                        <li key={index} className={structureActive === index ? css['active'] : ''} onClick={()=>getSubGroup(index)}>
                                          <img src={item.img} />
                                          <p>{item.mid} {item.name}</p>
                                          {
                                            item.click === 0 ? <div></div> : null
                                          }
                                        </li>
                                        // <li key={index} className={structureActive === index ? css['active'] : ''} onClick={()=>getSubGroup(index)}>
                                        //   <span>{item.mid}</span>
                                        //   <p>{item.name}</p>
                                        //   <img src={item.img} />
                                        //   {
                                        //     item.click === 0 ? <div></div> : null
                                        //   }
                                        // </li>
                                      ): null
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          ): null
                        }
                      </>
                    ): null
                  }
                </div>
              </div>

              {
                groupActive === -1 && !index ? (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        <h2>{intl.formatMessage({ id: "配置详情" })}</h2>
                        <div className={css['box-top-tools']}>
                          <div style={{marginLeft: 10}}>
                            <SearchPartInput 
                              mcid={mcid || (data.data && data.data.mcid) }
                              brandcode={brandcode || data.brandCode}
                              vin={vin || ''}  
                            />
                          </div>
                          
                          {/* <span>
                            <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']} style={{ height: `calc(100vh - ${pageType === '2' ? '162' : '242'}px)` }}>
                        <div className={css['detail']}>
                          {
                            data.data && data.data.config_list && data.data.config_list.map((item: {title: string, value: string}, index: number)=>{
                              return (
                                <p key={index}>
                                  <label>{item.title}：</label>
                                  <span>{item.value}</span>
                                </p>
                              )
                            })
                          }
                          
                        </div>
                      </div>
                    </div>
                    {
                      data.data && data.data.full_imglogo ? (
                        <img className={css['model-img']} alt="icon" src={data.data.full_imglogo} />
                      ): null
                    }
                  </div>
                ): (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        {
                          subGroupShow.content ? (
                            <h2>{intl.formatMessage({ id: "分组" })}({ filter ? subGroup.length : groupTotal.sub })</h2>
                          ): (
                            <h2>{intl.formatMessage({ id: "分组" })}({ filter ? structure.length : groupTotal.structure })</h2>
                          )
                        }
                        
                        <div className={css['box-top-tools']}>
                          <button onClick={()=>back()} className={css['other']}><i className="iconfont icon-left" />{intl.formatMessage({ id: "返回" })}</button>
                          <button className={css['other']} onClick={()=>setStructureShowLS()}><i className={structureShow ? "iconfont icon-qiehuantupian" : "iconfont icon-liebiao1"} style={{color: '#EBBC00'}} />{structureShow ? intl.formatMessage({ id: "图片" }) : intl.formatMessage({ id: "列表" })}</button>
                          {
                            vin ? (
                              <>
                                <button className={css['other']} onClick={()=>setConfigVisible(true)}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} />{intl.formatMessage({ id: "配置" })}</button>
                                <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont icon-guolv" style={{color: '#616BF1'}} />{filter ? intl.formatMessage({ id: "未过滤" }) : intl.formatMessage({ id: "过滤" })}</button>
                              </>
                            ): null
                          }
                          
                          <div style={{marginLeft: 10}}>
                            <SearchPartInput 
                              mcid={mcid || (data.data && data.data.mcid) }
                              brandcode={brandcode || data.brandCode}
                              vin={vin || ''}  
                            />
                          </div>
                          {/* <span>
                            <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']} style={{ height: `calc(100vh - ${pageType === '2' ? '162' : '242'}px)` }}>
                        <div className={css['detail']}>
                          {
                            structureShow ? (
                              <>
                                <table className={css['table-header']}>
                                  <thead>
                                    <tr>
                                      <th style={{width: '10%'}}>{intl.formatMessage({ id: "图片" })}</th>
                                      <th style={{width: '5%'}}>MG</th>
                                      <th style={{width: '5%'}}>SG</th>
                                      <th style={{width: '10%'}}>ILL-NO</th>
                                      <th style={{width: '15%'}}>{intl.formatMessage({ id: "名称" })}</th>
                                      <th style={{width: '15%'}}>{intl.formatMessage({ id: "备注" })}</th>
                                      <th style={{width: '40%'}}>{intl.formatMessage({ id: "车型" })}</th>
                                    </tr>
                                  </thead>
                                </table>
                                <div className={css['list']}>
                                  <table>
                                    <tbody>
                                    {
                                      subGroupShow.content ? (
                                        subGroup.map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? (css.grey + ' ' + css['filter']) : css['filter'])} onClick={()=>goDetail(structureActive, index)}>
                                                <td style={{width: '10%'}}><img src={item.img} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name || item.standard_subgroup_name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      ): (
                                        structure.map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? css.grey : css['filter'])} onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}}>
                                                <td style={{width: '10%'}}><img src={item.img} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name || item.standard_subgroup_name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      )
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ): (
                              <ul className={css['group']}>
                                {
                                  subGroupShow.content ? (
                                    subGroup.map((item: any, index: number)=>{
                                      return (
                                        filter || item.colorvalue ? (
                                          <li key={index} onClick={()=>goDetail(structureActive, index)} className={item.colorvalue ? '' : css['filter']}>
                                            <span className={css.name}>{item.name}</span>
                                            <img src={item.img} />
                                            <p>{item.mid}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                        ): null
                                      )
                                    })
                                  ): (
                                    structure.map((item: any, index: number)=>{
                                      return (
                                        filter || item.colorvalue ? (
                                          <li key={index}  onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}} className={item.colorvalue ? '' : css['filter']}>
                                            <span className={css.name}>{item.name}</span>
                                            <img src={item.img} />
                                            <p>{brandcode === 'benz' ? item.subgroup : (item.mid || item.standard_subgroup_name)}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                        ): null
                                      )
                                    })
                                  )
                                }
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </Spin>
        ): null
      }
      
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid || data.data.mcid }
            brandcode={brandcode || data.brandCode}
            vin={vin || ''}
          />
        ): null
      }
      <Modal
        title={intl.formatMessage({ id: "配置" })}
        open={configVisible}
        onCancel={()=>setConfigVisible(false)}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder="Please input key" onChange={(e)=>searchConfig(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              config.map((item: {title: string, value: string}, index: number)=>{
                return (
                  <div className={css['item']} key={index}>
                    <label>{item.title}</label>
                    <span>{item.value}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      <Modal
        title={selectConfig.data.desc || intl.formatMessage({ id: "选择配置信息" })}
        open={selectConfigVisible}
        onCancel={()=>setSelectConfigVisible(false)}
        width={1000}
      >
        <div className={css['select-config']}>
          <div className={css['select-brand']}>
            <span>{selectConfig.data.res_desc}</span>
            <img src={`${cdnsPath}/img/${selectConfig.data.brandCode}.png`} />
          </div>
          <table>
            <thead>
              <tr>
                {
                  selectConfig.data.columns && selectConfig.data.columns.map((item: any, index: number)=>{
                    return (
                      <th key={index}>{item.title}</th>
                    )
                  })
                }
              </tr>
            </thead>
          </table>
          <div className={css['select-scroll']}>
            <table>
              <tbody>
                {
                  selectConfig.data.list && selectConfig.data.list.map((item: any, index: number)=>{
                    return (
                      <tr onClick={()=>clickConfig(item)} key={index}>
                        {
                          selectConfig.data.columns.map((title: any, i: number)=>{
                            return (
                              <td key={i}>
                                {
                                  title.type === 'img' ? (
                                    <img src={item[title.dataIndex]} style={{height: 80}} />
                                  ): (
                                    <>{item[title.dataIndex]}</>
                                  )
                                }
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {/* {
        progressVisible ? (
          <div className={css.pollingVin}>
            <div>
              <span style={{width: progress * 50 + '%'}}></span>
            </div>
          </div>
        ): null
      } */}



      {
        supplementOpen ? (
          <Supplement
            companyInfo={companyInfo}
            onCancel={() => setSupplementOpen(false)}
            onConfirm={() => {getUserInfo()}}
          />
        ): null
      }
      
    </>
  );
}

export default Vin
