import { Button, Col, Modal, Table, Row, Input, Checkbox, Cascader, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styles from './index.module.scss'
import orderApi from "../../api/orderApi";
import MainContext from '../../stores/stores'
import { useIntl } from 'react-intl'

const Address: React.FC<any> = (props) => {
  const intl = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])

 
  const columns: any = [
    {
      title: intl.formatMessage({ id: '收货人' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: '所在区域' }),
      dataIndex: 'area',
      render: (text: any, record: any) => {
        return <div>{record.province}{record.city}{record.area}</div>
      }
    },
    {
      title: intl.formatMessage({ id: '详细地址' }),
      dataIndex: 'address',
    },
    {
      title: intl.formatMessage({ id: '联系电话' }),
      dataIndex: 'phone',
    },
    {
      title: intl.formatMessage({ id: '操作' }),
      dataIndex: 'uid',
      align: 'center',
      render: (text: any, record: any) => {
        return (
          <div className="flex-c-c">
            <span className="pointer" style={{ color: '#595959' }} onClick={() => { setOpen(true); setActiveItem({...record}) }}>{intl.formatMessage({ id: '修改' })}</span>
            <span className="red pointer mlr30" onClick={() => delItem(record)}>{intl.formatMessage({ id: '删除' })}</span>
            {
              record.is_default ? (
                <span style={{ color: '#fff', background: '#096BED', padding: '2px 5px', width: 60 }}>{intl.formatMessage({ id: '默认' })}</span>
              ): <span className="pointer" style={{ color: '#333333', width: 60 }} onClick={() => setDefault(record)}>{intl.formatMessage({ id: '设为默认' })}</span>
            }
          </div>
        )
      }
    },
  ]

  useEffect(() => {
    getList()
  },[])
  const getList = () => {
    setLoading(true)
    orderApi.getAddressList().then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        setList(res.data)
      }
    })
  }

  const delItem = (record: any) => {
    orderApi.delAddress({ address_id: record.address_id}).then((res: any) => {
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功" }))
        getList()
      }
    })
  }

  const setDefault = (record: any) => {
    let params = {
      ...record,
      is_default: 1
    }
    orderApi.modifyAddress(params).then((res: any) => {
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功" }))
        getList()
      }
    })
  }

  return (
    <div className={styles['address'] + ' p10'}>
      <div className="right">
        <Button icon={<i className="iconfont icon-tianjia" />} type="primary" ghost  onClick={() => { setOpen(true); setActiveItem({}) }}>
          {intl.formatMessage({ id: '新增地址' })}
        </Button>
      </div>
      <Table
        className="mt10"
        columns={columns}
        loading={loading}
        dataSource={list}
        pagination={false}
      />

      {
        open ? (
          <AddAddress
            item={activeItem}
            onCancel={() => setOpen(false)}
            refresh={() => getList()}
          />
        ): null
      }
    </div>
  )
}


interface PropsIn {
  item: any
  onCancel: Function
  refresh?: Function
}

const AddAddress: React.FC<PropsIn> = (props) => {
  const { commonStore } = useContext(MainContext)
  const { areaOptions } = commonStore
  const { item = { province: '', city: '', area: '' }, onCancel, refresh = () => {}  } = props
  const lang = localStorage.getItem('lang') || 'zh'
  const intl = useIntl()

  const [activeItem, setActiveItem] = useState<any>(item)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [identifyLoading, setIdentifyLoading] = useState<boolean>(false)

  const [identifyKeyword, setIdentifyKeyword] = useState<string>("")

  const setAreas = (value: any) => {
    setActiveItem({
      ...activeItem,
      province: value[0] || '',
      city: value[1] || '',
      area: value[2] || ''
    })
  }
  const identify = () => {
    setIdentifyLoading(true)
    orderApi.identifyAddress({ message: identifyKeyword }).then((res: any) => {
      setIdentifyLoading(false)
      if(res.code === 1) {
        setActiveItem({...activeItem, ...res.data})
      }
    })
  }
  const submit = () => {
    let zhKeys = ['province', 'city', 'area']
    let mustKeys = ['address', 'name', 'phone']
    if(lang === 'zh') mustKeys = [...zhKeys, ...mustKeys]
    let _activeItem: any = {}
    mustKeys.forEach((key: string) => _activeItem[key] = '')
    _activeItem = {..._activeItem, ...activeItem}
    let is = false
    Object.keys(_activeItem).forEach((key: string) => {
      if(mustKeys.includes(key) && !_activeItem[key]) is = true
    })
    if(is) {
      message.warning(intl.formatMessage({ id: "请先填写必填信息!" }))
      return false
    }
    setSubmitLoading(true)
    let api: any
    if(_activeItem.uid) {
      api = orderApi.modifyAddress(_activeItem)
    }else {
      api = orderApi.addAddress(_activeItem)
    }
    api.then((res: any) => {
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功" }))
        onCancel()
        refresh()
      }
    })
  }
  return (
    <Modal
      title={activeItem.uid ? intl.formatMessage({ id: '修改地址' }) : intl.formatMessage({ id: '新增地址' })}
      open={true}
      onCancel={() => onCancel()}
      width={800}
      footer={null}
    >
      <div className={styles['add-address']}>
        <div className={styles['lf']}>
          {
            lang === 'zh' ? (
              <Row align="middle" className="mt15">
                <Col className="right pr10" span={5}>
                  <span className="red">*</span>
                  {intl.formatMessage({ id: '所在区域' })}
                </Col>
                <Col  span={19}>
                  <Cascader
                    placeholder={intl.formatMessage({ id: "选择省/市/区" })}
                    style={{ width: '100%' }}
                    fieldNames={{ label: 'name', value: 'name' }}
                    options={areaOptions}
                    value={activeItem.province ? [activeItem.province, activeItem.city, activeItem.area] : undefined}
                    onChange={(value: any) => setAreas(value)}
                  />
                </Col>
              </Row>
            ): null
          }
          <Row className="mt15" align="middle">
            <Col className="right pr10" span={5}>
              <span className="red">*</span>
              {intl.formatMessage({ id: '详细信息' })}
            </Col>
            <Col  span={19}>
              <Input.TextArea value={activeItem.address} onChange={(e: any) => setActiveItem({...activeItem, address: e.target.value})} minLength={2} placeholder={intl.formatMessage({ id: "请输入详细地址信息，如道路、门牌号等信息" })} />
            </Col>
          </Row>
          <Row className="mt15" align="middle">
            <Col className="right pr10" span={5}>
              <span className="red">*</span>
              {intl.formatMessage({ id: '收货人姓名' })}
            </Col>
            <Col  span={19}>
              <Input value={activeItem.name} onChange={(e: any) => setActiveItem({...activeItem, name: e.target.value})} placeholder={intl.formatMessage({ id: '收货人姓名' })} />
            </Col>
          </Row>
          <Row className="mt15" align="middle">
            <Col className="right pr10" span={5}>
              <span className="red">*</span>
              {intl.formatMessage({ id: '手机号码' })}
            </Col>
            <Col  span={19}>
              <Input value={activeItem.phone} onChange={(e: any) => setActiveItem({...activeItem, phone: e.target.value})} placeholder={intl.formatMessage({ id: '手机号码' })} />
            </Col>
          </Row>
          <Row className="mt15" align="middle">
            <Col  span={5}>
            </Col>
            <Col  span={19}>
              <Checkbox checked={activeItem.is_default ? true : false} onClick={() => setActiveItem({...activeItem, is_default: activeItem.is_default ? 0 : 1})}/> 
              <span className="ml5">{intl.formatMessage({ id: '设置为默认收货地址' })}</span>
            </Col>
          </Row>
          <Row className="mt20" align="middle">
            <Col  span={5}>
            </Col>
            <Col  span={19}>
              <Button type="primary" loading={submitLoading} onClick={() => submit()}>{intl.formatMessage({ id: '保存' })}</Button>
            </Col>
          </Row>
        </div>
        <div className={styles['rt'] + ' mt15'}>
          <Input.TextArea value={identifyKeyword} style={{width: '100%', height: '100%'}} onChange={(e: any) => setIdentifyKeyword(e.target.value)} minLength={3} placeholder={intl.formatMessage({ id: "粘贴信息，自动拆分姓名、电话和地址" })} />
          <div className={styles['btn']}>
            <Button size="small" type="primary" style={{width: 90}} loading={identifyLoading} onClick={() => identify()}>{intl.formatMessage({ id: '粘贴并识别' })}</Button>
          </div>
        </div>
      </div>
      
    </Modal>
  )
}

export default Address

export {
  AddAddress
}