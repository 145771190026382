import React, { useContext, useEffect, useState, useRef } from "react";
import { Button, Col, Modal, Table, Row, Input, Checkbox, Cascader, message, Select, Radio, Spin } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import styles from './index.module.scss'
import orderApi from "../../api/orderApi";
import accountApi from "../../api/accountApi";
import MainContext from '../../stores/stores'
import axios from "axios";
import { useIntl } from 'react-intl'
const CancelToken = axios.CancelToken
const controller = new AbortController();

const Invoice: React.FC<any> = (props) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [open, setOpen] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<any>([])

 
  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage({ id: '名称' }),
      dataIndex: 'name',
      fixed: 'left',
      width: 150,
			render: (text: any, record: any) => {
				let src = 'https://userimgs.007cst.com/20240704/c25400c38f5918702b906bf341504c22.png'
				if(record.invoice_type === 'special') {
					src = 'https://userimgs.007cst.com/20240704/abee55f6d4c6ce178bd890f86b437753.png'
				} 
				return (
					<div className="flex-s-c">
						<img className="mr5" style={{ height: 20 }} src={src} />
						{text}
					</div>
				)
			}
    },
    {
      title: intl.formatMessage({ id: '税号' }),
      dataIndex: 'tax_number',
      width: 150
    },
    {
      title: intl.formatMessage({ id: '单位地址' }),
      dataIndex: 'address',
      width: 120
    },
    {
      title: intl.formatMessage({ id: '电话号码' }),
      dataIndex: 'phone',
      width: 100
    },
    {
      title: intl.formatMessage({ id: '开户银行' }),
      dataIndex: 'bank_name',
      width: 90
    },
    {
      title: intl.formatMessage({ id: '银行账户' }),
      dataIndex: 'bank',
      width: 100
    },
    {
      title: intl.formatMessage({ id: '操作' }),
      dataIndex: 'uid',
      align: 'center',
      fixed: 'right',
      width: 180,
      render: (text: any, record: any) => {
        return (
          <div className="flex-c-c">
            <span className="pointer" style={{ color: '#096BED' }} onClick={() => { setOpen(true); setActiveItem({...record}) }}>{intl.formatMessage({ id: '修改' })}</span>
            <span className="red pointer mlr30" onClick={() => delItem(record)}>{intl.formatMessage({ id: '删除' })}</span>
            {
              record.is_default ? (
                <span style={{ color: '#fff', background: '#096BED', padding: '2px 5px', width: 90, wordBreak: 'keep-all' }}>{intl.formatMessage({ id: '已设为默认' })}</span>
              ): <span className="pointer" style={{ color: '#333333', width: 90, wordBreak: 'keep-all' }} onClick={() => setDefault(record)}>{intl.formatMessage({ id: '设为默认' })}</span>
            }
          </div>
        )
      }
    },
  ]

  useEffect(() => {
    getList()
  },[])
  const getList = () => {
    setLoading(true)
    orderApi.getInvoiceList().then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        setList(res.data)
      }
    })
  }

  const delItem = (record: any) => {
    orderApi.delInvoice({ invoice_id: record.invoice_id}).then((res: any) => {
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功" }))
        getList()
      }
    })
  }
  const setDefault = (record: any) => {
    let params = {
      ...record,
      is_default: 1
    }
    orderApi.modifyInvoice(params).then((res: any) => {
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功" }))
        getList()
      }
    })
  }
  const toInvoiceHistory = () => {
    navigate("/mine/invoice/history")
  }
  return (
    <div className={styles['address'] + ' p10'}>
      <div className="right">
        <Button style={{background: '#F1F7FF', borderColor: '#F1F7FF', color: '#0C6BED', marginRight: 20}} onClick={toInvoiceHistory}>
          {intl.formatMessage({id: '开票记录'})}
        </Button>
        <Button icon={<i className="iconfont icon-tianjia" />} type="primary" ghost  onClick={() => { setOpen(true); setActiveItem({}) }}>
          {intl.formatMessage({ id: '新增抬头' })}
        </Button>
      </div>
      <div className="mt10">
        <Table
          rowKey="uid"
          columns={columns}
          loading={loading}
          dataSource={list}
          pagination={false}
          scroll={{x: 1200}}
        />
      </div>
      

      {
        open ? (
          <AddInvoice
            item={activeItem}
            onCancel={() => setOpen(false)}
            refresh={() => getList()}
          />
        ): null
      }
    </div>
  )
}


interface PropsIn {
  item: any
  onCancel: Function
  refresh?: Function
}

let timeout: any = null
const AddInvoice: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const { item = { }, onCancel, refresh = () => {}  } = props
  const [activeItem, setActiveItem] = useState<any>({invoice_type: "plain", ...item})
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  
  const [option, setOption] = useState<any>([])
	const [selectOpen, setSelectOpen] = useState<boolean>(false)
	const [selectLoading, setSelectLoading] = useState<boolean>(false)
	
	let inputRef = useRef<any>(null)
  let getCancel = useRef<any>(null)

  const companySearch = (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken(c => getCancel.current = c)
		setSelectLoading(true)
    const getCompanyOption = () => {
      accountApi.getCompanyList({
        cancelToken: rToken,
        signal: controller.signal,
        keyword: value
      }).then((res: any) => {
				setSelectLoading(false)
        if(res.code === 1) {
          let _companyOption = res.data.items.map((item: any) => {
            return {
              label: item.name,
              value: item.credit_no
            }
          })
					if(_companyOption.length) {
						setSelectOpen(true)
					}
          setOption(_companyOption)
        }
      }).catch((err: any) => {
				setSelectLoading(false)
			})
    }
    if (value && value.length >= 3) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setOption([]);
    }
  }
  
  const submit = () => {
    let mustKeys = ['name', 'tax_number']
		if(activeItem.invoice_type === 'special') {
			mustKeys = ['name', 'tax_number', 'address', 'phone', 'bank_name', 'bank']
		}
    let _activeItem: any = {}
    mustKeys.forEach((key: string) => _activeItem[key] = '')
    _activeItem = {..._activeItem, ...activeItem}
    let is = false
    Object.keys(_activeItem).forEach((key: string) => {
      if(mustKeys.includes(key) && !_activeItem[key]) is = true
    })
    if(is) {
      message.warning(intl.formatMessage({ id: "请先填写必填信息!" }))
      return false
    }
    setSubmitLoading(true)
    let api: any
    if(_activeItem.uid) {
      api = orderApi.modifyInvoice(_activeItem)
    }else {
      api = orderApi.addInvoice(_activeItem)
    }
    api.then((res: any) => {
			setSubmitLoading(false)
      if(res.code === 1) {
        message.success(intl.formatMessage({ id: "操作成功" }))
        onCancel()
        refresh()
      }
    })
  }
  return (
    <Modal
      title={activeItem.uid ? intl.formatMessage({ id: '修改抬头' }) : intl.formatMessage({ id: '新增抬头' })}
      open={true}
      onCancel={() => onCancel()}
      width={600}
      footer={null}
    >
			<Row className="mt20" align="middle">
			  <Col className="right pr10" span={5}>
			    <span className="red">*</span>
			    {intl.formatMessage({ id: '发票类型' })}
			  </Col>
			  <Col  span={15}>
					<Radio.Group value={activeItem.invoice_type} onChange={(e: any) => setActiveItem({...activeItem, invoice_type: e.target.value})}>
						<Radio key={'plain'} value={'plain'}>{intl.formatMessage({ id: '普通发票' })}</Radio>
						<Radio key={'special'} value={'special'}>{intl.formatMessage({ id: '专用发票' })}</Radio>
					</Radio.Group>
			  </Col>
			</Row>
      <Row className="mt15" align="middle">
        <Col className="right pr10" span={5}>
          <span className="red">*</span>
          {intl.formatMessage({ id: '名称' })}
        </Col>
        <Col  span={15} className="flex-s-c">
          <Select
            style={{ width: 0 }}
            showSearch
            placeholder={intl.formatMessage({ id: "请输入抬头名称" })}
            options={option}
            filterOption={false}
            defaultActiveFirstOption={false}
            onSearch={companySearch}
            value={activeItem.name}
            onChange={(value: any, _option: any) => {
              setActiveItem({...activeItem, name: _option.label, tax_number: _option.value})
            }}
          />
					
					<div className={styles['invoice-company-select']}>
						<Input.Search
							onSearch={() => companySearch(activeItem.name)}
							ref={inputRef} 
							placeholder={intl.formatMessage({ id: "请输入抬头名称" })}
							onFocus={() => setSelectOpen(true)} 
							onBlur={() => setSelectOpen(false)}
							value={activeItem.name} 
							onChange={(e: any) => setActiveItem({...activeItem, name: e.target.value})} 
						/>
						{
							selectOpen ? (
								<div className={styles['options']}>
									<Spin spinning={selectLoading}>
										<div className={styles['list']}>
											{
												option.map((item: any, index: number) => {
													console.log(item)
													return (
														<div 
															key={index} 
															onClick={() => console.log(123123)} 
															onMouseDown={() => setActiveItem({...activeItem, name: item.label, tax_number: item.value})}
															className={styles['item']}>{item.label}</div>
													)
												})
											}
										</div>
									</Spin>
								</div>
							): null
						}
					</div>
        </Col>
      </Row>
      <Row className="mt15" align="middle">
        <Col className="right pr10" span={5}>
          <span className="red">*</span>
          {intl.formatMessage({ id: '税号' })}
        </Col>
        <Col  span={15}>
          <Input value={activeItem.tax_number} onChange={(e: any) => setActiveItem({...activeItem, tax_number: e.target.value})} placeholder={intl.formatMessage({ id: "纳税人识别号" })} />
        </Col>
      </Row>
      <Row className="mt15" align="middle">
        <Col className="right pr10" span={5}>
					{
						activeItem.invoice_type === 'special' ? (
							<span className="red">*</span>
						): null
					}
          {intl.formatMessage({ id: '单位地址' })}
        </Col>
        <Col  span={15}>
          <Input value={activeItem.address} onChange={(e: any) => setActiveItem({...activeItem, address: e.target.value})}  placeholder={intl.formatMessage({ id: "(选填)公司地址" })} />
        </Col>
      </Row>
      
      <Row className="mt15" align="middle">
        <Col className="right pr10" span={5}>
					{
						activeItem.invoice_type === 'special' ? (
							<span className="red">*</span>
						): null
					}
          {intl.formatMessage({ id: '电话号码' })}
        </Col>
        <Col  span={15}>
          <Input value={activeItem.phone} onChange={(e: any) => setActiveItem({...activeItem, phone: e.target.value})} placeholder={intl.formatMessage({ id: "(选填)公司电话" })} />
        </Col>
      </Row>
      <Row className="mt15" align="middle">
        <Col className="right pr10" span={5}>
					{
						activeItem.invoice_type === 'special' ? (
							<span className="red">*</span>
						): null
					}
          {intl.formatMessage({ id: '开户银行' })}          
        </Col>
        <Col  span={15}>
          <Input value={activeItem.bank_name} onChange={(e: any) => setActiveItem({...activeItem, bank_name: e.target.value})} placeholder={intl.formatMessage({ id: "(选填)开户银行" })} />
        </Col>
      </Row>
      <Row className="mt15" align="middle">
        <Col className="right pr10" span={5}>
					{
						activeItem.invoice_type === 'special' ? (
							<span className="red">*</span>
						): null
					}
          {intl.formatMessage({ id: '银行账户' })}        
        </Col>
        <Col  span={15}>
          <Input value={activeItem.bank} onChange={(e: any) => setActiveItem({...activeItem, bank: e.target.value})} placeholder={intl.formatMessage({ id: "(选填)银行账户" })} />
        </Col>
      </Row>
      <Row className="mt15" align="middle">
        <Col  span={5}>
        </Col>
        <Col  span={15}>
          <Checkbox checked={activeItem.is_default ? true : false} onClick={() => setActiveItem({...activeItem, is_default: activeItem.is_default ? 0 : 1})}/> 
          <span className="ml5">{intl.formatMessage({ id: '设置为默认发票' })}</span>
        </Col>
      </Row>
      <Row className="mt20" align="middle">
        <Col  span={5}>
        </Col>
        <Col  span={15}>
          <Button type="primary" loading={submitLoading} onClick={() => submit()}>{intl.formatMessage({ id: '保存' })}</Button>
        </Col>
      </Row>
      
    </Modal>
  )
}

export default Invoice

export {
  AddInvoice
}