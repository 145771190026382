const LocaleConfig = {
  // common
  saveText: 'Save',
  setText: 'Settings',
  saveSuccessText: 'Settings saved',
  saveFailedText: 'Failed to save Settings',
  addFriendSuccessText: 'Friend added',
  applyFriendSuccessText: 'Friend request sent',
  addFriendFailedText: 'Failed to add friend',
  applyFriendFailedText: 'Failed to send friend request',
  okText: 'OK',
  cancelText: 'Cancel',
  deleteText: 'Delete',
  recallText: 'Recall',
  forwardText: 'Forward',
  forwardFailedText: 'Forward failed',
  sendBtnText: 'send',
  replyText: 'Reply',
  commentText: 'comment',
  recentSessionText: 'Recent session',
  you: 'you',
  deleteFriendText: 'Delete friend',
  confirmDeleteText: 'Delete friend',
  confirmDeleteFriendText: 'Are you sure you want to delete the friend?',
  deleteFriendSuccessText: 'Friend deleted',
  deleteFriendFailedText: 'Failed to delete friend',
  blackText: 'Blocked',
  removeBlackText: 'Remove from blocklist',
  blackSuccessText: 'Blocked',
  blackFailedText: 'Failed to block user',
  removeBlackSuccessText: 'Unblocked',
  removeBlackFailedText: 'Failed to unblock user',
  maxSelectedText: 'A maximum of {} friends can be selected',
  selectedText: 'Selected',
  friendsText: 'Friends',
  strangerText: 'stranger(s)',
  emailErrorText: 'Invalid email address',
  uploadLimitText: 'A maximum size of {} MB is allowed for upload',
  uploadLimitUnit: 'M',
  uploadImgFailedText: 'Upload failed',
  accountText: 'Account',
  nickText: 'Nickname',
  genderText: 'Gender',
  phoneText: 'Phone',
  emailText: 'Email',
  signText: 'Bio',
  accountPlaceholder: 'Enter your account',
  teamIdPlaceholder: 'Please enter the group ID',
  nickPlaceholder: 'Enter your nickname',
  genderPlaceholder: 'Select your gender',
  phonePlaceholder: 'Enter mobile phone number',
  emailPlaceholder: 'Enter email address',
  signPlaceholder: 'Enter your bio',
  searchInputPlaceholder: 'Search for friends or groups',
  searchTeamMemberPlaceholder: 'Search for team member',
  searchText: 'search',
  man: 'Male',
  woman: 'Female',
  unknow: 'Prefer not to Answer',
  welcomeText: 'Welcome to CommsEase Messenger',
  notSupportMessageText: 'The type of message is not supported',
  applyTeamText: 'Join',
  applyTeamSuccessText: 'Apply team success',
  rejectText: 'Reject',
  acceptText: 'Accept',
  inviteTeamText: 'Invite',
  applyFriendText: 'Add',
  acceptResultText: 'Accepted',
  rejectResultText: 'Rejected',
  beRejectResultText: 'Friend request rejected',
  passResultText: 'Friend request accepted',
  rejectTeamInviteText: 'declined group invitation',
  updateTeamAvatar: 'Updated group avatar',
  updateTeamName: 'The group name was updated as',
  updateTeamIntro: 'Updated the group introduction',
  updateTeamInviteMode: 'Updated the "Invite permission" to',
  updateTeamUpdateTeamMode: 'Updated the "Edit permission" to​',
  updateAllowAt: 'Updated the "@everyone permission" to',
  updateTeamMute: 'group ban',
  onlyTeamOwner: 'Only the owner',
  teamAll: 'Everyone',
  closeText: 'Close',
  openText: 'Open',
  inviteText: 'Invite',
  aliasText: 'Remark',
  updateAliasSuccessText: 'Remarks modified',
  updateAliasFailedText: 'Failed to modify remark',
  sendText: 'Send',
  noPermission: 'No permission',
  callDurationText: 'call duration',
  callCancelText: 'canceled',
  callRejectedText: 'rejected',
  callTimeoutText: 'timeout',
  callBusyText: 'busy line',

  // conversation-kit
  onDismissTeamText: 'Group dismissed',
  onRemoveTeamText: 'You were removed',
  textMsgText: 'Text message',
  audioMsgText: 'Audio message',
  videoMsgText: 'Video message',
  fileMsgText: 'File message',
  callMsgText: 'CDR message',
  geoMsgText: 'Location message',
  imgMsgText: 'Image message',
  notiMsgText: 'Notification',
  robotMsgText: 'Chatbot message',
  tipMsgText: 'Tip',
  unknowMsgText: 'Unknown message',
  deleteSessionText: 'Delete',
  muteSessionText: 'Enable Do-Not-Disturb',
  unmuteSessionText: 'Disable Do-Not-Disturb',
  deleteStickTopText: 'Unpin',
  addStickTopText: 'Pin',
  beMentioned: '[You were mentioned]',

  // contact-kit
  teamListTitle: 'Groups',
  friendListTitle: 'Friends',
  blackListTitle: 'Blocklist',
  msgListTitle: 'Message Center',
  blackListDesc:
    '（You will not receive messages from any contacts in the list）',
  teamMenuText: 'Groups',
  friendMenuText: 'Friends',
  blackMenuText: 'Blocklist',
  msgMenuText: 'Message Center',
  acceptedText: 'Request approved',
  acceptFailedText: 'Failed to approve the request',
  rejectedText: 'Request rejected',
  rejectFailedText: 'Failed to reject the request',
  getApplyMsgFailedText: 'Failed to get the messsage',

  // search-kit
  addFriendText: 'Add friend',
  createTeamText: 'Create group',
  joinTeamText: 'Join group',
  beRemoveTeamText: 'Removed from the group',
  addButtonText: 'Add',
  addSuccessText: 'Added',
  addFailedText: 'Adding friend failed',
  createButtonText: 'Create',
  createTeamSuccessText: 'Group created',
  createTeamFailedText: 'Failed to create the group',
  chatButtonText: 'Chat',
  getRelationFailedText: 'Failed to get relationship',
  accountNotMatchText: 'No account found',
  teamIdNotMatchText: 'No such group number found',
  searchButtonText: 'Search',
  searchTeamPlaceholder: 'Enter a group name',
  teamTitle: 'Group name',
  teamAvatarText: 'Group avatar',
  addTeamMemberText: 'Add member',
  searchEmptyText: 'You have not added friends or groups yet',
  searchNoResText: 'No search results yet',
  searchFriendTitle: 'Friends',
  searchTeamTitle: 'Groups',
  notSupportJoinText:
    'The discussion group cannot be joined directly, please contact the administrator to add you to the group',

  // chat-kit
  sendToText: 'To',
  sendUsageText:
    '（Send a message by pressing Enter and break a line using Shift+ Enter）',
  sendEmptyText: 'Unable to send an empty message',
  teamMutePlaceholder: 'The group owner has muted the group',
  enterTeamText: 'joined the group chat',
  leaveTeamText: 'left the group chat',
  teamMuteText: 'Mute group',
  muteAllTeamSuccessText: 'Mute all',
  unmuteAllTeamSuccessText: 'Unmute all',
  muteAllTeamFailedText: 'Failed to mute all',
  unmuteAllTeamFailedText: 'Failed to unmute all members',
  updateTeamSuccessText: 'Edit succeeded',
  updateTeamFailedText: 'Edit failed',
  leaveTeamSuccessText: 'You have left the group',
  leaveTeamFailedText: 'Failed to leave the group',
  dismissTeamSuccessText: 'Group dismissed',
  dismissTeamFailedText: 'Failed to dismiss the group',
  addTeamMemberSuccessText: 'Member added',
  addTeamMemberFailedText: 'Failed to add member',
  addTeamMemberConfirmText: 'Select group members',
  newGroupOwnerText: 'become the new group owner',
  removeTeamMemberText: 'Remove',
  removeTeamMemberConfirmText: 'Whether to remove the member？',
  removeTeamMemberSuccessText: 'Member removed',
  removeTeamMemberFailedText: 'Failed to remove member',
  teamTitleConfirmText: 'Group name is required',
  teamAvatarConfirmText: 'Group avatar is required',
  teamIdText: 'Group ID',
  teamSignText: 'Introduction',
  teamTitlePlaceholder: 'Enter group name',
  teamSignPlaceholder: 'Enter a description',
  teamOwnerText: 'Group owner',
  teamManagerText: 'Admin',
  teamManagerLimitText: 'Only admins can edit group information',
  teamMemberText: 'Group member',
  teamInfoText: 'Group information',
  teamPowerText: 'Permissions',
  dismissTeamText: 'Dismiss group',
  transferOwnerText: 'transfer group owner',
  transferTeamFailedText: 'transfer group owner failed',
  transferTeamSuccessText: 'transfer group owner success',
  transferOwnerConfirmText:
    'Are you sure you want to transfer the group ownership',
  transferToText: 'transfer to',
  dismissTeamConfirmText: 'Are you sure you want to dismiss the group',
  leaveTeamTitle: 'Leave group',
  leaveTeamConfirmText: 'Are you sure you want to leave the group',
  personUnit: 'person',
  leaveTeamButtonText: 'Delete and leave',
  sendMsgFailedText: 'Failed to send the message',
  getHistoryMsgFailedText: 'Failed to get the message history',
  sendBlackFailedText: 'You were added to blocklist',
  recallMessageText: 'A message was recalled',
  reeditText: 'Re-edit',
  addChatMemberText: 'Add member',
  chatHistoryText: 'Chat history',
  noMoreText: 'No more messages',
  receiveText: 'You have a new message',
  strangerNotiText:
    'You are chatting with a non-friend. Be discreet about sharing your personal information.',
  teamInviteModeText: 'Only administrators can invite other users',
  nickInTeamText: 'Nickname',
  editNickInTeamText: 'Edit',
  unreadText: 'unread',
  readText: 'read',
  allReadText: 'All read',
  amap: 'Amap',
  txmap: 'Tencent map',
  bdmap: 'Baidu map',
  customMsgText: 'Custom message',
  joinTeamSuccessText: 'Join group success',
  teamManagerEditText: 'Member management',
  teamManagerEmptyText: 'No group management personnel yet',
  teamOwnerAndManagerText: 'Group owner and administrator',
  updateTeamManagerSuccessText: 'Group administrator modified',
  updateTeamManagerFailText: 'Failed to modify group administrator',
  userNotInTeam: 'Member is no longer in the group',
  teamAtModeText: 'Who can @everyone',
  beAddTeamManagersText: 'You were added as an administrator',
  beRemoveTeamManagersText: 'You were removed as an administrator',
  recallReplyMessageText: 'The message has been recalled or deleted',
  updateMyMemberNickSuccess: 'Update my group nickname success',
  updateMyMemberNickFailed: 'Update my group nickname failed',
  updateBitConfigMaskSuccess: 'Update group message Do-Not-Disturb success',
  updateBitConfigMaskFailed: 'Update group message Do-Not-Disturb failed',
  onlineText: '[Online]',
  offlineText: '(Offline)',
  // emoji 不能随便填，要用固定 key
  Laugh: '[Laugh]',
  Happy: '[Happy]',
  Sexy: '[Sexy]',
  Cool: '[Cool]',
  Mischievous: '[Mischievous]',
  Kiss: '[Kiss]',
  Spit: '[Spit]',
  Squint: '[Squint]',
  Cute: '[Cute]',
  Grimace: '[Grimace]',
  Snicker: '[Snicker]',
  Joy: '[Joy]',
  Ecstasy: '[Ecstasy]',
  Surprise: '[Surprise]',
  Tears: '[Tears]',
  Sweat: '[Sweat]',
  Angle: '[Angle]',
  Funny: '[Funny]',
  Awkward: '[Awkward]',
  Thrill: '[Thrill]',
  Cry: '[Cry]',
  Fretting: '[Fretting]',
  Terrorist: '[Terrorist]',
  Halo: '[Halo]',
  Shame: '[Shame]',
  Sleep: '[Sleep]',
  Tired: '[Tired]',
  Mask: '[Mask]',
  ok: '[ok]',
  AllRight: '[All right]',
  Despise: '[Despise]',
  Uncomfortable: '[Uncomfortable]',
  Disdain: '[Disdain]',
  ill: '[ill]',
  Mad: '[Mad]',
  Ghost: '[Ghost]',
  Angry: '[Angry]',
  Unhappy: '[Unhappy]',
  Frown: '[Frown]',
  Broken: '[Broken]',
  Beckoning: '[Beckoning]',
  Ok: '[Ok]',
  Low: '[Low]',
  Nice: '[Nice]',
  Applause: '[Applause]',
  GoodJob: '[Good job]',
  Hit: '[Hit]',
  Please: '[Please]',
  Bye: '[Bye]',
  First: '[First]',
  Fist: '[Fist]',
  GiveMeFive: '[Give me five]',
  Knife: '[Knife]',
  Hi: '[Hi]',
  No: '[No]',
  Hold: '[Hold]',
  Think: '[Think]',
  Pig: '[Pig]',
  NoListen: '[No listen]',
  NoLook: '[No look]',
  NoWords: '[No words]',
  Monkey: '[Monkey]',
  Bomb: '[Bomb]',
  Cloud: '[Cloud]',
  Rocket: '[Rocket]',
  Ambulance: '[Ambulance]',
  Poop: '[Poop]',
}

const emojiLocaleConfig = {}

export default LocaleConfig
