import { Col, Row, Input, Button, message, Select, Upload, Spin, Popconfirm } from "antd";
import React, { useContext, useEffect, useState, useRef } from "react";
import styles from './index.module.scss'
import MainContext from '../../stores/stores'
import avtorPng from '../../assets/img/avtor.png'
import accountApi from "../../api/accountApi";
import axios from 'axios'
import { useIntl } from 'react-intl'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Supplement } from './Company'
import orderApi from '../../api/orderApi'


const CancelToken = axios.CancelToken
const controller = new AbortController();

let timeout: any = null
const Setting: React.FC<any> = (props) => {
  const { commonStore, setCommonStore } = useContext(MainContext)
  const { userInfo  } = commonStore
  const lang = localStorage.getItem("lang") || 'zh'
  const intl = useIntl()
  const [nameEdit, setNameEdit] = useState<boolean>(false)
  const [companyEdit, setCompanyEdit] = useState<boolean>(false)
  const [emailEdit, setEmailEdit] = useState<boolean>(false)

  const [uploading, setUploading] = useState<boolean>(false)
  const [companyLoading, setCompanyLoading] = useState<boolean>(false)
  const [nameLoading, setNameLoading] = useState<boolean>(false)
  const [emailLoading, setEmailLoading] = useState<boolean>(false)
  const [modifyInfo, setModifyInfo] = useState<any>({})
  const [option, setOption] = useState<any>([])
  const [supplementOpen, setSupplementOpen] = useState<boolean>(false)
  const [companyInfo, setCompanyInfo] = useState<any>({})
  
  let getCancel = useRef<any>(null)
  

  useEffect(() => {
    getUserInfo()
  },[])
  

  const companySearch = (value: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    getCancel.current && getCancel.current('请求中断…')
    const rToken = new CancelToken(c => getCancel.current = c)
    const getCompanyOption = () => {
      accountApi.getCompanyList({
        cancelToken: rToken,
        signal: controller.signal,
        keyword: value
      }).then((res: any) => {
        if(res.code === 1) {
          let _companyOption = res.data.items.map((item: any) => {
            return {
              label: item.name,
              value: item.credit_no
            }
          })
          setOption(_companyOption)
        }
      })
    }
    if (value && value.length >= 3) {
      timeout = setTimeout(getCompanyOption, 300);
    } else {
      setOption([]);
    }
  }

  const getUserInfo = () => {
    accountApi.getUserInfo().then((res: any) => {
      if(res.code === 1) {
        res.data.company = res.data.reg_company_name
        setModifyInfo(res.data)
        setCommonStore({value: res.data, type: 'userInfo'})
        getCompanyInfo(res.data.repair_shop_id)
      }
    })
  }
  const getCompanyInfo = (repair_shop_id: string) => {
    orderApi.getCompanyInfo({
      repair_shop_id: repair_shop_id,
    }).then((res: any) => {
      if(res.code === 1) {
        setCompanyInfo(res.data)
      }
    })
  }
  const submit = (cancel?: Function, loadigFun?: any) => {
    let { company, company_tax_id, username, phone, address, wechat_nick, avatar } = modifyInfo
    let params = {
      company,
      company_tax_id,
      username,
      phone,
      address,
      wechat_nick,
      avatar
    }
    loadigFun && loadigFun(true)
    accountApi.updateUserInfo(params).then((res: any) => {
      loadigFun && loadigFun(false)
      if(res.code === 1) {
        getUserInfo()
        if(cancel) cancel()
        message.success(intl.formatMessage({ id: "操作成功" }))
      }
    })
  }
  const uploadChange = ({file}: any) => {
    if(file.status === "uploading") {
      setUploading(true)
    }
    if(file.status === 'done') {
      setUploading(false)
      if(file.response.code === 1) {
        let data = file.response.data.files
        let key = Object.keys(data)[0]
        let url = data[key].url
        modifyInfo['avatar'] = url
        submit()
      }
    }
    console.log(file)
  }
  const applyVertify = () => {
    let uid = commonStore.userInfo && commonStore.userInfo.uid ? commonStore.userInfo.uid : ''
    accountApi.toApplyVertify({
      uid: uid
    }).then((res: any) => {
      if(res.code === 1) {
        message.success("已再次申请权限")
      }
    })
  }
  const logoutCompany = () => {
    let params = {
      uid: userInfo.id,
      old_repair_shop_id: userInfo.repair_shop_id,
      new_repair_shop_id: undefined
    }
    orderApi.updateUserCompany(params).then((res: any) => {
      if(res.code === 1) {
        message.success("操作成功！")
        getUserInfo()
      }
    })
  }
  const logout = () => {
    accountApi.logout().then((res: any) => {
      if(res.code === 1) {
        localStorage.removeItem("token")
        localStorage.removeItem("ycid")
        localStorage.removeItem("openid")
        window.location.href = '/home'
      }
    })
  }
  return (
    <div className={styles['setting']}>
      <div className={styles['h_title']}>
        {intl.formatMessage({ id: '设置' })}
      </div>
      <div className={styles['body']} style={{ height: 'calc(100vh - 330px)' }}>
        <Row className="plr20 ptb15" align="middle" style={{ borderBottom: '1px solid #D8D8D8' }}>
          <Col span={6}>
            {intl.formatMessage({ id: '头像' })}
          </Col>
          <Col span={14}>
            <img src={userInfo.avatar || 'https://cdns.007vin.com/img/autorepair/avtor.png'} style={{ width: 40, height: 40, borderRadius: 40 }} alt="" />
          </Col>
          <Col className="right pointer " span={4}>
            <Spin spinning={uploading}>
              <Upload
                name="files"
                action="/v1/api/common/file/upload"
                showUploadList={false}
                onChange={uploadChange}
              >
                <span className="p-color">{intl.formatMessage({ id: '修改' })}</span>
              </Upload>
            </Spin>
            
          </Col>
        </Row>
        <Row className="plr20 ptb15" align="middle" style={{ borderBottom: '1px solid #D8D8D8' }}>
          <Col span={6}>
            {intl.formatMessage({ id: '昵称' })}
          </Col>
          {
            nameEdit ? (
              <>
                <Col span={14}>
                  <Input size="large" value={modifyInfo.wechat_nick} onChange={(e: any) => setModifyInfo({...modifyInfo, wechat_nick: e.target.value})} />
                  <div className="mt15">
                    <Button size="small" style={{height: 30}} className="mr10" onClick={() => setNameEdit(false)}>{intl.formatMessage({ id: '取消' })}</Button>
                    <Button loading={nameLoading} size="small" style={{height: 30}} type="primary" 
                      onClick={() => submit(() => setNameEdit(false), (value: boolean) => setNameLoading(value))}>
                        {intl.formatMessage({ id: '确定' })}
                    </Button>
                  </div>
                </Col>
                <Col className="right pointer p-color" span={4}>
                  
                </Col>
              </>
            ): (
              <>
                <Col span={14} className="flex-s-c">
                  <span className="b">{userInfo.wechat_nick || userInfo.phone}</span>
                  {/* 普通员工 */}
                  {
                    userInfo.verify_status && userInfo.verify_status === 1 ? <div className={styles['auth'] + ' ' + styles['auth1'] + ' ml10 flex-c-c'}>
                      {
                        userInfo.job_name === '店长' ? <i className='iconfont icon-dianchang mr5' /> : null
                      }
                      {
                        userInfo.job_name === '技师' ? <i className='iconfont icon-jishi mr5' /> : null
                      }
                      {
                        userInfo.job_name === '采购' ? <i className='iconfont icon-caigou mr5' /> : null
                      }
                      {
                        userInfo.job_name === '前台' ? <i className='iconfont icon-qiantai mr5' /> : null
                      }
                      {
                        userInfo.job_name !== '前台' && userInfo.job_name !== '采购' && userInfo.job_name !== '技师' && userInfo.job_name !== '店长' ? <i className='iconfont icon-gangwei mr5' /> : null
                      }
                      {userInfo.job_name ? intl.formatMessage({ id: userInfo.job_name }) : ''}
                    </div> : null
                  }
                  {
                    userInfo.verify_status && userInfo.verify_status === 1 ? null : (userInfo.verify_status && userInfo.verify_status === 2 ? <span style={{background: 'rgba(233, 91, 86, 0.1)', padding: '3px 6px', fontSize: 12, color: '#E95B56', borderRadius: '2px', marginLeft: 5}}>{intl.formatMessage({ id: '被拒绝' })}</span> : <span style={{background: 'rgba(61, 123, 246, 0.1)', padding: '3px 6px', fontSize: 12, color: '#3D7BF6', borderRadius: '2px', marginLeft: 5}}>{intl.formatMessage({ id: '审核中' })}</span>)
                  }
                  {
                    userInfo.verify_status && userInfo.verify_status === 2 ? <span style={{color: '#3A78F6', fontSize: 12, marginLeft: 10, cursor: 'pointer'}} onClick={() => setSupplementOpen(true)}>{intl.formatMessage({ id: '再次申请' })}</span> : null
                  }
                </Col>
                <Col className="right pointer p-color" span={4} onClick={() => setNameEdit(true)}>
                  {intl.formatMessage({ id: '修改' })}
                </Col>
              </>
            )
          }
          
        </Row>
        {/* <Row className="plr20 ptb15" align="middle" style={{ borderBottom: '1px solid #D8D8D8' }}>
          <Col span={6}>
            邮箱
          </Col>
          {
            emailEdit ? (
              <>
                <Col span={14}>
                  <Input size="large" value={modifyInfo.email} onChange={(e: any) => setModifyInfo({...modifyInfo, email: e.target.value})} />
                  <div className="mt15">
                    <Button size="small" style={{height: 30}} className="mr10" onClick={() => setEmailEdit(false)}>取消</Button>
                    <Button loading={emailLoading} size="small" style={{height: 30}} type="primary" 
                      onClick={() => submit(() => setEmailEdit(false), (value: boolean) => setEmailLoading(value))}>
                        确定
                    </Button>
                  </div>
                </Col>
                <Col className="right pointer p-color" span={4}>
                  
                </Col>
              </>
            ): (
              <>
                <Col span={14}>
                  {userInfo.email}
                </Col>
                <Col className="right pointer p-color" span={4} onClick={() => setEmailEdit(true)}>
                  修改
                </Col>
              </>
            )
          }
        </Row> */}
        <Row className="plr20 ptb15" align="middle" style={{ borderBottom: '1px solid #D8D8D8' }}>
          <Col span={6}>
            {lang === 'zh' ? intl.formatMessage({ id: '手机号' }) : intl.formatMessage({ id: '邮箱' })}
          </Col>
          <Col span={14} className="b">
            {userInfo.phone}
            {
              (commonStore.userInfo && commonStore.userInfo.verify_status && commonStore.userInfo.verify_status !== 1) || (commonStore.userInfo && !commonStore.userInfo.verify_status) ? <span className={styles['no-auth']}>{intl.formatMessage({ id: '暂无权限' })}</span> : null
            }
            
          </Col>
          <Col className="right pointer p-color" span={4}>
            {/* {
              (commonStore.userInfo && commonStore.userInfo.verify_status && commonStore.userInfo.verify_status !== 1) || (commonStore.userInfo && !commonStore.userInfo.verify_status) ? <span className={styles['again']} onClick={applyVertify}>{intl.formatMessage({ id: '再次申请权限' })}</span> : null
            } */}
          </Col>
        </Row>
        {/* <Row className="plr20 ptb15" align="middle" style={{ borderBottom: '1px solid #D8D8D8' }}>
          <Col span={6}>
            {intl.formatMessage({ id: '公司名称' })}
          </Col>
          {
            companyEdit ? (
              <>
                <Col span={14}>
                  {
                    lang === 'zh' ? (
                      <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={intl.formatMessage({ id: "公司名称" })}
                        options={option}
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        onSearch={companySearch}
                        value={modifyInfo.company}
                        onChange={(value: any, _option: any) => {
                          setModifyInfo({...modifyInfo, company: _option.label, company_tax_id: _option.value})
                        }}
                      />
                    ): <Input placeholder={intl.formatMessage({ id: "公司名称" })}  size="large" value={modifyInfo.company} onChange={(e: any) => setModifyInfo({...modifyInfo, company: e.target.value})} />
                  }
                  <div className="mt15">
                    <Button size="small" style={{height: 30}} className="mr10" onClick={() => setCompanyEdit(false)}>{intl.formatMessage({ id: '取消' })}</Button>
                    <Button loading={companyLoading} size="small" style={{height: 30}} type="primary" 
                      onClick={() => submit(() => setCompanyEdit(false), (value: boolean) => setCompanyLoading(value))}
                    >{intl.formatMessage({ id: '确定' })}</Button>
                  </div>
                </Col>
                <Col className="right pointer p-color" span={4}>
                  
                </Col>
              </>
            ): (
              <>
                <Col className="b" span={14}>
                  {userInfo.company}
                </Col>
                <Col  className="right pointer p-color" span={4}>
                  <span onClick={() => setCompanyEdit(true)}>{intl.formatMessage({ id: '修改' })}</span>
                </Col>
              </>
            )
          }
          
        </Row> */}
        <Row className="plr20 ptb15" align="middle" style={{ borderBottom: '1px solid #D8D8D8' }}>
          <Col span={6}>
            {intl.formatMessage({ id: '公司名称' })}
          </Col>
          <Col span={14} className="b flex">
            {userInfo.repair_shop_name}
            {
              userInfo.validate_status === 1 ? (
                <span className="flex-c-c ml5" style={{ borderRadius: 10, background: 'rgba(136, 136, 136, 0.1)', color: '#096BED', fontSize: 12, padding: '2px 10px' }}>{intl.formatMessage({ id: '已认证' })}</span>
              ): null
            }
          </Col>
          <Col className="right pointer " span={4}>
            {
              userInfo.is_repair_shop_admin ? null : <Popconfirm
                trigger={['click']}
                title={
                  <div>
                    {intl.formatMessage({ id: '退出公司，退出后将不再属于该公司！' })}
                  </div>
                }
                icon={<ExclamationCircleOutlined color="#FAAD14" />}
                onConfirm={logoutCompany}
              >
                <span className="red" >{intl.formatMessage({ id: '退出' })}</span>
              </Popconfirm>
            }
          </Col>
        </Row>

        <div className="mt30 center">
          <Button danger onClick={() => logout()}>{intl.formatMessage({ id: '退出登录' })}</Button>
        </div>
      </div>
      {
        supplementOpen ? (
          <Supplement
            companyInfo={companyInfo}
            onCancel={() => setSupplementOpen(false)}
            onConfirm={() => {getUserInfo()}}
          />
        ): null
      }
    </div>
  )
}

export default Setting