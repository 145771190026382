const zh = {
  locale: 'zh-CN',
  messages: {
    // 'home': '首页',
    // 'search': '搜索',
    // 'inputVin': '请输入VIN码',
    // 'inputCode': '请输入OE或厂家编码',
    // 'dragFile': '粘贴或拖动文件到此区域以进行识别',
    // 'selectImage': '选择图像',
    // 'history': '历史记录',
    // 'recommendedSorting': '推荐排序',
    // 'priceHighToLow': '价格由高到低',
    // 'priceLowToHigh': '价格由低到高',
    // 'searchPartName': '搜索零件名称',
    // 'noData': '暂无数据',
    // 'noToBePrice': '该商品尚未定价',
    // 'afterAuthPrice': '认证公司后显示价格',
    // 'toLook': '去看看',
    // 'selectSearch': '选择要查询的内容',
    // 'year': '年份',
    // 'displacement': '排量',
    // 'transmission': '变速箱',
    // 'Chassis': '底盘',
    // 'Drive': '驱动',
    // 'EngineModel': '发动机型号',
    // 'backSearch': '返回搜索',
    // 'addCart': '加入购物车',
    // 'buyNow': '立即购买',
    // 'code': '产品编码',
    // 'OE': '原厂OE',
    // 'partParams': '零件参数',
    // 'warrantyPolicy': '质保政策',
    // 'deliveryMethod': '配送方式',
    // 'spots': '现货',
    // 'adaptOe': '适配OE',
    // 'adaptModel': '适配车型',
    // 'afterSaleService': '售后服务',
    // 'all': '全部',
    // 'address': '收货地址',
    // 'noAddress': '暂无地址',
    // 'addAddress': '新增地址',
    // 'default': '默认',
    // 'modify': '修改',
    // 'delete': '删除',
    // 'orderInfo': '订单信息',
    // 'SerialNo': '序号',
    // 'commodity': '商品',
    // 'brand': '品牌',
    // 'quality': '品质',
    // 'attribute': '属性',
    // 'price4S': '4S价',
    // 'number': '数量',
    // 'source': '来源',
    // 'subtotal': '小计',
    // 'invoicing': '开具发票',
    // 'noInvoice': '暂无发票',
    // 'pleaseAddInvoice': '请添加发票',
    // 'invoiceTypePop': '发票类型: 增值税纸质普通发票',
    // 'selectedInvoice': '请选择开具发票信息',
    // 'remark': '备注',
    // 'inputRemark': '请输入备注',
    // 'payType': '支付方式',
    // 'onlinePay': '在线支付',
    // 'creditPay': '挂帐支付',
    // 'deliveryPay': '货到付款',
    // 'payAmount': '支付金额',
    // 'consignee': '收货人',
    // 'adminAddress': '管理收货地址',
    // 'selectAddress': '请选择收货地址',
    // 'cart': '购物车',
    // 'allSelected': '全选',
    // 'operation': '操作',
    // 'prompt': '提示',
    // 'askRemoveCart': '是否移出购物车？',
    // 'confirm': '确定',
    // 'cancel': '取消',
    // 'deleteSuccess': '删除成功',
    // 'groupDelete': '批量删除',
    // 'allTotal': '合计',
    // 'settlement': '结算',
    // 'paySuccess': '支付成功',
    // 'jumpMyOrder': `{name}秒后自动跳转我的订单`,
    // 'backHome': '返回首页',
    // 'confirmPay': '确认支付',
    // 'wechatScan': '请使用微信扫一扫',
    // 'scanQRCode': '扫描二维码支付',
    // 'confirmPayment': '确认付款',
    // 'againUpload': '重新上传',
    // 'bestSellers': '热销商品',
    // 'VinQuery': 'Vin查询',
    // 'NumberQuery': '编号查询',
    // 'CarQuery': '车型查询',
    // "login": '登录',
    // 'register': '注册',
    // 'forgotPassword': '忘记密码',
    // 'phone': '手机号',
    // 'email': '邮箱',
    // 'password': '密码',
    // 'smscode': '验证码',
    // 'getSmscode': '获取验证码',
    // 'ok': '确定',
    // 'companyName': '公司名称',
    // 'backLogin': '返回登录',
    '共count家店': '共 {count} 家店'
  }
}


export default zh